@charset "utf-8";

@import "landingpage";
@import "infopage";
@import "covid_key_visual";
@import "stoerer";

@font-face {
    font-family: 'Daytona W01 Light';
    src: url('../../fonts/Daytona W01 Light.eot');
    src: url('../../fonts/Daytona W01 Light.woff') format('woff'),
    url('../../fonts/Daytona W01 Light.otf') format('opentype');
}

h2.claim {
    font-family: "Daytona W01 Light", sans-serif;
    font-size: 22px;
    color: #fff;
}

ul.main-menu li.covid-highlight a {
    color: $covid-color;
}
