//small
@media screen and (max-width: $small-breakpoint) {
    .hide-for-small, .hide-for-medium-down {
        display: none !important;
    }
    .small-screen-badge {
        display: none !important;
    }
}

//medium
@media screen and (min-width: $small-breakpoint + 1) and (max-width: $medium-breakpoint) {
    .hide-for-medium-down, .hide-for-medium-up, .hide-for-medium {
        display: none !important;
    }
}

//large
@media screen and (min-width: $medium-breakpoint + 1) {
    .hide-for-large, .hide-for-medium-up {
        display: none !important;
    }
}
