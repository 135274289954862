.sticky-nav-bar {
    position: relative;
}

.is-sticky .sticky-nav-bar {
    background-color: transparent;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--spacing-s);

    > div:first-child {
        margin: 0 0 5px 0;
    }
}
