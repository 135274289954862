.panel {
    border: $border-dark;
    padding: var(--spacing-s);
    background: $grey-light;

    &.info {
        background-color: $blue-light;
    }

    &.warning {
        background-color: $yellow-light;
    }

    &.error {
        background-color: lighten($alert-color, 50%);
    }

    &.success {
        background-color: $green-light;
    }

    &div.info, &div.warning, &div.error, &div.success {
        background-image: none;
        padding: var(--spacing-s);
        margin: 0;
    }
}
