input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
select,
textarea {
    font: normal $font-size-t1 $font-family-t1;
    width: 100%;
    max-width: var(--max-width);
    border-radius: 2px;
    background-color: #fff;
    border: $border-medium;
    padding: var(--spacing-xs);
    color: $font-color-input;

    &:focus {
        border-color: $action-blue;
        box-shadow: 0 0 var(--spacing-xxs) $action-blue;
    }
}

textarea {
    vertical-align: top;
    height: $form-textarea-height;
}

select {
    background-color: #fafafa;
    cursor: pointer;
}

label {
    color: $type-grey-medium;
    line-height: $line-height-t1;
    font-size: $font-size-t1;
    font-weight: bold;
    display: block;

    &:not(.nested-checkable-input) {
        margin: 0 0 var(--spacing-xxs) 0;
    }

    + .clear-input {
        margin: 0;
        top: 36px;

        &.spinner {
            top: 34px;
        }
    }

    + ul.list-no-bullets + .clear-input {
        margin: 0;
        top: 113px;

        &.spinner {
            top: 111px;
        }
    }
}

.explanation {
    margin-top: var(--spacing-xxs);
}

input[type="checkbox"] + label, .font-weight-normal, .nested-checkable-input {
    color: $font-color-input;
    font-weight: normal;
}

fieldset {
    border: $border-medium;
    padding: var(--spacing-s);
    margin: var(--spacing-xxs) 0 var(--spacing) 0;

    legend {
        font-weight: bold;
        margin: 0 0 0 var(--spacing-xs);
        padding: 0 var(--spacing-xxs);
    }
}

input:not([type])[disabled],
input[type="text"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="month"][disabled],
input[type="week"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
input[type="color"][disabled],
textarea[disabled],
input:not([type])[readonly],
input[type="text"][readonly],
input[type="password"][readonly],
input[type="date"][readonly],
input[type="datetime"][readonly],
input[type="datetime-local"][readonly],
input[type="month"][readonly],
input[type="week"][readonly],
input[type="email"][readonly],
input[type="number"][readonly],
input[type="search"][readonly],
input[type="tel"][readonly],
input[type="time"][readonly],
input[type="url"][readonly],
input[type="color"][readonly],
textarea[readonly],
fieldset[disabled] input:not([type]),
fieldset[disabled] input[type="text"],
fieldset[disabled] input[type="password"],
fieldset[disabled] input[type="date"],
fieldset[disabled] input[type="datetime"],
fieldset[disabled] input[type="datetime-local"],
fieldset[disabled] input[type="month"],
fieldset[disabled] input[type="week"],
fieldset[disabled] input[type="email"],
fieldset[disabled] input[type="number"],
fieldset[disabled] input[type="search"],
fieldset[disabled] input[type="tel"],
fieldset[disabled] input[type="time"],
fieldset[disabled] input[type="url"],
fieldset[disabled] input[type="color"],
fieldset[disabled] textarea {
    background-color: #ddd;
    cursor: default;
}

input::-ms-clear {
    display: none;
}
