.favorite-star-icons {
    $width: $button-size-small;
    $height: $width;
    $iconWidth: $width - 2;
    $iconHeight: $iconWidth;
    $svgWidth: $width - 6;
    $svgWidthSmall: $svgWidth - 4;

    vertical-align: top;
    width: $width;
    height: $height;
    display: inline-block;
    overflow: hidden;

    &:not(:last-child) {
        margin-right: var(--spacing-xxs);
    }

    &:before {
        content: "";
        display: block;
        width: $iconWidth;
        height: $iconHeight;
        background-color: transparent;
        background-size: $svgWidth;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.star-marked:before {
        background-image: url("../../images/userfrontend/star-marked.svg");
    }

    &.star-unmarked:before {
        background-image: url("../../images/userfrontend/star.svg");
    }
}
