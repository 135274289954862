@charset "utf-8";

@import "mixins";
@import "browserfixes";
@import "badge";
@import "text";
@import "layout";
@import "grid";
@import "block_grid";
@import "columns_list";
@import "responsive_visibility";
@import "page_context_menu";
@import "list";
@import "form";
@import "burger_menu";
@import "navigation";
@import "expander";
@import "footer";
@import "header";
@import "info_box";
@import "kompendium_news";
@import "alerts";
@import "table";
@import "button";
@import "tabbox";
@import "modal";
@import "pagination";
@import "flex";
@import "clearinputspinner";
@import "panel";
@import "tooltip";
@import "responsive_iframe";
@import "blurb";
@import "print";
@import "key_visual";
@import "landingpage";
@import "pillow_button";
@import "nested_ordered_lists";
@import "filechooser";
@import "documentchooser";
@import "stoerer";

@font-face {
    font-family: 'Myriad Light';
    src: url('../../fonts/MyriadPro_Light.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/MyriadPro_Light.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/MyriadPro_Light.otf') format('opentype'); /* Safari, Android, iOS */
}

html, body {
    height: 100%;
}

html {
    box-sizing: border-box;
    font-size: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

* {
    position: relative;
}

body {
    font-size: $font-size-t1;
    background: #f1f1f1 url("../../images/springer/bg.png");
    word-wrap: break-word;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    image-rendering: -webkit-optimize-contrast;
    color: $font-color-body;
    cursor: auto;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.618;
    margin: 0;
}

a.disabled {
    pointer-events: none;
}
