.page-wrapper {
    max-width: var(--max-width);
    margin: 0 auto;
    box-shadow: 0 0 4px #BBB;
    background-color: #FFF;
}

.mb {
    margin-bottom: var(--spacing) !important;
    margin-top: 6px;
}

.mb-s {
    margin-bottom: var(--spacing-s) !important;
}

.mb-xs {
    margin-bottom: var(--spacing-xs) !important;
}

.mb-l {
    margin-bottom: var(--spacing-l) !important;
}

.mb-xl {
    margin-bottom: var(--spacing-xl) !important;
}

.mb-xxs {
    margin-bottom: var(--spacing-xxs) !important;
}

.mb-l {
    margin-bottom: var(--spacing-l) !important;
}

.mb-xl {
    margin-bottom: var(--spacing-xl) !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt {
    margin-top: var(--spacing) !important;
}

.mt-s {
    margin-top: var(--spacing-s) !important;
}

.mt-xs {
    margin-top: var(--spacing-xs) !important;
}

.mt-xxs {
    margin-top: var(--spacing-xxs) !important;
}

.mt-l {
    margin-top: var(--spacing-l) !important;
}

.mt-xl {
    margin-top: var(--spacing-xl) !important;
}

.mr {
    margin-right: var(--spacing) !important;
}

.mr-s {
    margin-right: var(--spacing-s) !important;
}

.mr-xs {
    margin-right: var(--spacing-xs) !important;
}

.ml {
    margin-left: var(--spacing) !important;
}

.ml-s {
    margin-left: var(--spacing-s) !important;
}

.ml-xs {
    margin-left: var(--spacing-xs) !important;
}

.ml-xxs {
    margin-left: var(--spacing-xxs) !important;
}

.pb {
    padding-bottom: var(--spacing) !important;
}

.pb-s {
    padding-bottom: var(--spacing-s) !important;
}

.pt-xs {
    padding-top: var(--spacing-xs) !important;
}
