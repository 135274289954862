div.infopage {
    header {
        margin-bottom: 0;
    }

    .claim {
        text-align: left;
        display: inline;
        font-size: $font-size-covid-headline-medium;
        background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency);
        position: absolute;
        bottom: var(--spacing);
        left: var(--spacing);
        border-radius: 0 0 0 5px;
        line-height: 1.15385;
        padding: var(--spacing-xxs) var(--spacing-s);

        @media only screen and (max-width: $small-breakpoint) {
            font-size: $font-size-covid-headline-small;
            left: 0;
            padding-left: var(--side-space);
        };
    }

    .content {
        p, .info-agents-list, a {
            font-family: "Daytona W01 Light", sans-serif;
            font-size: $info-font-size-small;
            line-height: 1.55556;
        }

        .large {
            font-size: $info-font-size-large;
            text-align: justify;
        }

        .dark {
            color: $info-font-color-dark;
            font-weight: bold;
            text-align: justify;
        }

        .light {
            color: $info-font-color-light;
            font-weight: bold;
        }

        a {
            color: $info-font-color-light;
        }

        .text-align-justify {
            text-align: justify;
        }
    }
}
