.cookie-banner {
    width: 90%;
    max-width: $cookie-banner-max-width;
    position: fixed;
    bottom: -100px;
    background-color: $mobile-dark-background;
    color: #fff;
    border: 1px solid $mobile-border-top;
    padding: var(--spacing-s);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 9px 9px 8px 1px rgba(0, 0, 0, 0.5);
    transition: bottom 1s;
    z-index: 1001;

    div {
        display: inline-block;
        vertical-align: -6px;
        padding-bottom: var(--spacing-xxs);
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    @media only screen and (max-width: $small-breakpoint) {
        button {
            margin-top: var(--spacing-s);
        }
    }
}
