/* Custom  Tooltip Container - Copied Text Message */
.custom-tooltip-container {
    display: flex;
    align-items: center;
    margin-top: -10px;
}

.custom-tooltip {
    display: none;
    padding: 5px 12px;
    background-color: $type-grey-medium;
    border-radius: 6px;
    color: $divider-white;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;

    &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -8px;
        width: 0;
        height: 0;
        border-bottom: 8px solid darken($type-grey-medium, 20);
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
}
