@charset "utf-8";

.list-container {
    position: relative;
    overflow: hidden;
}

.atc-code-list {
    grid-column-gap: 0;
    @include transition(max-height .5s, visibility .5s);
}

.atc-code-list.hidden {
    max-height: 0;
    visibility: hidden;
    @include transform(translateY(-100%));
    @include animation(slide-out .5s forwards);
}

.atc-code-list.visible {
    /* max-height muss größer als die tatsächliche Größe sein */
    max-height: 5000px;
    visibility: visible;
    @include animation(slide-in .5s forwards);
}

.atc-code a {
    font-size: $font-size-t3;
    line-height: $line-height-t3;
}

@include keyframes(slide-in) {
    0% {
        @include transform(translateY(-100%));
    }
    100% {
        @include transform(translateY(0%));
    }
}

@include keyframes(slide-out) {
    0% {
        @include transform(translateY(0%));
    }
    100% {
        @include transform(translateY(-100%));
    }
}

@media only screen and (max-width: $medium-breakpoint) {
    .atc-code-list .atc-code {
        padding: 0;

        a {
            width: 100%;
            height: 100%;
            padding: var(--spacing-xxs);
            display: block;
        }
    }
}

.atc-code.current {
    font-weight: bold;
    text-decoration: underline;
}
