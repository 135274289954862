.interaction-contact-list {
    border: $border;
    box-shadow: inset 0 1px 0 0 #ffffff;

    .contact-info {
        position: relative;
        padding: calc(var(--spacing) / 4) var(--spacing) var(--spacing-xs) var(--spacing);
        border-bottom: $border;
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        > div:first-child {
            > div {
                display: inline-block;
            }

            > div:first-child {
                font-weight: bold;
                margin-right: var(--spacing-xxs);
            }
        }

        > div:last-child {
            font-size: $font-size-small;
            font-family: $font-family-monospace;

        }
    }

    .contact-text {
        padding: var(--spacing-xs) var(--spacing) var(--spacing-xs) var(--spacing);
    }
}

#submit-interaction-contact-btn-container {
    text-align: right;

    button {
        float: none;
    }
}
