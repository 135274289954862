.pillow-button {
    display: inline-block;
    margin: var(--spacing-l) var(--side-space) 0;
    padding: 0 15px;
    cursor: pointer;
    line-height: $burger-button-height;
    font-size: $font-size-h5;
    transition: right 0.25s ease-in-out;

    &.expanded {
        box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.35), inset 0 -1px 0 rgba(255, 255, 255, 0.75);
        background-color: $grey;
        border: none;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.85);
    }
}
