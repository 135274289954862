.password-checker {
    overflow: hidden;
    @include flex();
    position: relative;
    width: 45%;
    max-width: 150px;

    .rating-container {
        height: 7px;
        flex-basis: 100%;
        background-color: #ebebeb;
        margin-top: 16px;
        margin-left: 1px;

        div {
            box-shadow: inset 0 var(--spacing-xxs) 6px 1px rgba(0, 0, 0, 0.2);
            height: inherit;
            width: 0;
        }
    }
}

.strength-color {
    &.red {
        background-color: $alert-color;
    }

    &.yellow {
        background-color: $warning-color;
    }

    &.green {
        background-color: $success-color;
    }
}
