.small-action-button {
    $width: $button-size-small;
    $height: $width;
    $iconWidth: $width - 2;
    $iconHeight: $iconWidth;
    $svgWidth: $width - 6;
    $svgWidthSmall: $svgWidth - 4;

    vertical-align: top;
    width: $width;
    height: $height;
    border: $border-dark;
    display: inline-block;
    border-radius: var(--spacing-xxs);
    background-color: $gradient-grey-light-light;
    background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
    overflow: hidden;

    &:not(:last-child) {
        margin-right: var(--spacing-xxs);
    }

    &:hover {
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark 5%);
    }

    &:before {
        content: "";
        display: block;
        width: $iconWidth;
        height: $iconHeight;
        background-color: transparent;
        background-size: $svgWidth;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.edit:before {
        background-size: $svgWidthSmall;
    }

    &.add:before {
        background-size: $svgWidthSmall;
    }

    &.delete:before {
        background-size: $svgWidthSmall;
    }

    &.print-preview, &.pdf-export {
        cursor: pointer;
        margin-top: 3px;
    }

}
