@mixin flex() {
    display: flex;

    > * {
        display: inline-block;
    }
}

@mixin list-no-bullets() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    animation: $animations;
}

@mixin keyframes($animationName) {
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin transition($args...) {
    transition: $args;
}

@mixin transform($transforms) {
    transform: $transforms;
}

@mixin column-count($count) {
    display: grid;
    grid-template-columns: repeat($count, 1fr);
    grid-gap: var(--spacing-xs);
    align-items: stretch;
}
