@media only screen and (max-width: $medium-breakpoint) {
    ul.main-menu + .burger-button {
        position: absolute;
        top: -56px;
        right: var(--side-space);
        width: $burger-button-width;
        background: url("../../images/userfrontend/burger_stripes.svg") no-repeat center center;

        &:hover {
            background-image: url("../../images/userfrontend/burger_stripes_dark.svg");
        }
    }
}

.burger-button {
    margin: 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    height: $burger-button-height;
    line-height: $burger-button-height;
    text-align: center;
    border: $border;

    &:hover {
        border-color: #bcbcbc;
    }

    &:focus {
        border: 2px solid $action-blue-light;
        border-radius: var(--spacing-xxs);
    }

    a span::after {
        box-shadow: none;
    }

    &.expanded {
        box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.35), inset 0 -1px 0 rgba(255, 255, 255, 0.75);
    }
}

.burger-menu {
    display: none;
    z-index: 1000;

    ul {
        @include list-no-bullets();

        li, div {
            border-bottom: 1px solid $mobile-border-bottom;
            border-top: 1px solid $mobile-border-top;

            &:hover a {
                color: $action-blue-light;
                text-decoration: none;
            }
        }

        li a {
            padding: 11px var(--side-space) 10px;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    ul:nth-child(2) {
        box-shadow: inset 0 6px 5px #333333;
    }

    .current-user-info {
        border-bottom: none;
        padding: 11px var(--side-space) 10px;
        width: 100%;
        height: 100%;
        display: block;

        a {
            color: $sign-out-link-color;
        }

        > * {

        }
    }

    a, span, .current-user-info {
        color: #fff;
    }

    * {
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-size: $font-size-h5;
    }

    padding-left: 0;
    margin-left: 0;
    background: $mobile-dark-background url("../../images/springer/bg_mobile_menu.png") 0 0 repeat;

    .has-sub-nav {
        padding: 0;
        position: relative;

        span {
            display: block;
            padding: 11px var(--side-space) 10px;
        }

        > ul {
            @include list-no-bullets();

            display: none;

            li {
                a {
                    padding: 11px calc(var(--side-space) + var(--spacing-s)) 10px;
                    color: #fff;
                }
            }
        }

        &:after {
            background: url("../../images/userfrontend/arrow_right_grey.svg") no-repeat center center;
            width: 6px;
            height: 43px;
            background-size: 6px;
            content: " ";
            position: absolute;
            right: var(--spacing);
            top: 0;
        }

        &.expanded {
            border-top-color: #fff;

            span {
                background-color: #fff;
                color: $action-blue;
            }

            &:after {
                background-image: url("../../images/userfrontend/arrow_down_blue.svg");
                width: 12px;
                background-size: 12px;
            }
        }
    }

    li.has-main-sub-nav {
        padding: 0;
        position: relative;

        span {
            display: block;
            padding: 11px var(--side-space) 10px;
        }

        > ul.menu_level_1 {
            @include list-no-bullets();

            display: none;

            li {
                a {
                    padding: 11px calc(var(--side-space) + var(--spacing-s)) 10px;
                    color: #fff;
                }
            }


        }

        &:after {
            background: url("../../images/userfrontend/arrow_right_grey.svg") no-repeat center center;
            width: 6px;
            height: 43px;
            background-size: 6px;
            content: " ";
            position: absolute;
            right: var(--spacing);
            top: 0;
        }

        &.expanded {
            border-top-color: #fff;

            span {
                background-color: #fff;
                color: $action-blue;
            }

            ul.menu_level_1 {
                display: block;
            }

            &:after {
                background-image: url("../../images/userfrontend/arrow_down_blue.svg");
                width: 12px;
                background-size: 12px;
            }
        }
    }
}
