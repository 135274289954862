.grid {
    .pager {
        .current {
            width: auto;
            margin-left: 5px;
            display: inline-block;
            margin-right: 5px;
            height: 28px;
            margin-bottom: 0;
        }

        select {
            width: auto;
            display: inline-block;
            height: 28px;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: var(--spacing);
        }
    }

    th {
        padding: 0 var(--spacing-xs) 0 var(--spacing-xs);
    }

    td {
        padding: var(--spacing-xs);

        &:not(.grid-column-actions) {
            text-align: left;
        }
    }

    .grid-row-filters {
        th {
            padding: var(--spacing-xxs);

            input, select {
                height: 28px;
                margin: 0;
            }

            select {
                padding: var(--spacing-xxs);
            }
        }
    }

    .grid-row-actions {
        @include list-no-bullets();

        li {
            display: inline-block;
            margin: var(--spacing-xxs);
        }
    }

    select {
        background-color: #fff;
    }

    .licenses-above-limit {
        .grid-column-numberOfUsedUserLicenses {
            color: $alert-color;
            font-weight: bold;
        }
    }

    .grid-row-titles {
        th {
            vertical-align: bottom;
        }
    }

    .filter-isCleared-warning {
        background-color: $alert-color;
        color: #fff;

        option:not(.filter-isCleared-warning) {
            background-color: #fff;
            color: $font-color-body;
        }
    }

    .sort-up {
        background: transparent url("../../images/userfrontend/arrow_up_grey.svg") no-repeat center center;
        width: 9px;
        height: 5px;
        display: inline-block;
        margin: 0 0 1px var(--spacing-xxs);
    }

    .sort-down {
        background: transparent url("../../images/userfrontend/arrow_down_grey.svg") no-repeat center center;
        width: 9px;
        height: 5px;
        display: inline-block;
        margin: 0 0 1px var(--spacing-xxs);
    }

    button.grid-apply-filter {
        float: none;
        margin-left: 0;
        padding: 0;
        vertical-align: top;

        &:focus {
            background-color: $gradient-grey-light-light;
            background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark 5%);
        }
    }
}

table tr td {
    span.grid_boolean_true, span.grid_boolean_1 {
        background: transparent url("../../images/userfrontend/confirm.svg") no-repeat center center;
        display: block;
        $size: 18px;
        background-size: $size;
        height: $size;
        width: $size;
        margin: 0 auto;
    }

    span.grid_boolean_false {
        background: transparent url("../../images/userfrontend/cross.svg") no-repeat center center;
        display: block;
        margin: 0 auto;
        height: 12px;
        width: 12px;
        background-size: 12px;
    }
}
