
@mixin keyframe-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    @include keyframe-fadeIn;
}

@mixin fadeIn( $arg ) {
    $keyframe-name: fadeIn;
    $duration: $arg;
    animation: $keyframe-name $duration;
}

@mixin keyframe-fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    @include keyframe-fadeOut;
}

@mixin fadeOut( $arg ) {
    $keyframe-name: fadeOut;
    $duration: $arg;
    animation: $keyframe-name $duration;
}

$fadeTime: 350ms;
$fadeTimeBg: 250ms;

.modal-dialog {
    display: none;
    border-radius: 3px;
    position: absolute;
    top: 0;
    width: 85%;
    max-width: $max-width * 0.85;
    z-index: 1005;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    padding: var(--spacing-l);
    border: $border-medium;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);

    h2 {
        padding-right: var(--spacing);
    }

    .close-reveal-modal {
        color: #aaa;
        cursor: pointer;
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
        position: absolute;
        top: var(--spacing-xs);
        right: var(--spacing-s);
    }

    &.fadeIn {
        display: block;
        @include fadeIn($fadeTime)
    }

    &.fadeOut {
        @include fadeOut($fadeTime)
    }
}

.modal-dialog-bg {
    display: none;

    background: #000;
    background: rgba(0, 0, 0, .45);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1004;

    &.fadeIn {
        display: block;
        @include fadeIn($fadeTimeBg)
    }

    &.fadeOut {
        @include fadeOut($fadeTimeBg)
    }
}
