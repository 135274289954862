#interaction_query_records {

    div:hover a {
        text-decoration: none;
    }

    .postfix {
        margin-top: 7px;
        padding-top: var(--spacing-xs);
        height: 37px;
    }
}

.entity-name {
    display: inline-block;
    padding-right: 5px;
}

.interaction-rating-title {

    &:not(:first-child) {
        margin-top: var(--spacing);
    }

    margin-bottom: var(--spacing-xxs);
}

h2 a {
    display: inline-block;
    padding-top: 1px;
    font-size: inherit;
    font-family: inherit;
}
