form[name="user_settings"], form[name="register_user"] {
    fieldset {
        div.mb:last-of-type {
            margin-bottom: 0 !important;
        }
    }
}

form[name="opt_in_settings"] {
    h2 label {
        font-weight: unset;
        font-size: unset;
        line-height: unset;
        color: unset;
    }
}
