@charset "utf-8";

// Layout
// CSS variables cant be used in media queries, so they need to be defined as sass variables
$small-breakpoint: 600px;
$medium-breakpoint: 1024px;
$responsive-table-breakpoint: 770px;
$max-width: 980px;
// max-width + icon size + spacing
$form-is-dirty-hint-breakpoint: $max-width + 30px + 24px;

$margin-top-to-center: 39px;
$margin-bottom-to-center: 63px;
$nav-height: 40px;

//Colors
$springer-logo-blue: #00285A;
$action-blue: #0176C3;
$action-blue-light: #B3DCF5;
$action-blue-light-light: #EDF5FB;
$active-link: #70bced;

$type-grey-dark: #0F0F0F;
$type-grey-medium: #2B2B2B;
$type-grey-light: #999;

$grey: #dcdcdc;
$grey-light: #f4f4f4;
$grey-medium: #cccccc;
$grey-dark: #B0B0B0;

$divider-white: #ffffff;
$mobile-dark-background: #3d3e43;
$mobile-border-bottom: #333333;
$mobile-border-top: #4c4d51;

$yellow: #ffcc00;
$yellow-light: #FCFBCD;
$yellow-dark: #C69F00;

$accent-orange: #EE7D11;
$orange: #e06310;
$orange-light: #F2853E;

$green: #428248;
$green-medium: #629C66;
$green-light: #e3fbba;
$green-dark: #29692D;

$blue: #38a0e5;
$blue-light: #ECF6FD;
$blue-dark: #128DDC;

$cta-color: #c2001e;
$highlight-color: $orange;
$highlight-color-light: $orange-light;

$alert-color: #c40606;
$success-color: $green;
$warning-color: $yellow;
$info-color: $blue;

$gradient-blue-light-light: #D7ECFA;
$gradient-blue-light-dark: #B3DCF5;
$gradient-grey-light-light: #F9F9F9;
$gradient-grey-light-dark: #EAEAEA;
$gradient-grey-dark-light: #f8f8f8;
$gradient-grey-dark-dark: #e5e5e5;

$horizontal-dots-background-hover: #c6cbd1;
$horizontal-dots-black-light: #444d56;

:root {
    --spacing: 24px;
    --spacing-xxl: 100px;
    --spacing-xl: 63px;
    --spacing-l: 39px;
    --spacing-s: 15px;
    --spacing-xs: 9px;
    --spacing-xxs: 3px;
    --side-space: 24px;
    --max-width: #{$max-width};

    --action-button-icon-size: 24px;
    --action-button-icon-size-small: 18px;
    --form-is-dirty-hint-icon-size: 30px;
    --work-in-progress-icon-size: 48px;
    --interaction-version-compare-dropdown-outer-min-width: 233px;

    --view-filter-border-radius: 3px;
    --view-filter-height-padding: 3px;
    --view-filter-height-padding-small: 9px;
}

@media screen and (max-width: $small-breakpoint) {
    :root {
        --side-space: 15px;
        --max-width: unset;
    }
}

//Font
$font-family-sans-serif: Arial, sans-serif;
$font-family-serif: $font-family-sans-serif;
$font-family-headline: "Myriad Light", sans-serif;
$font-family-monospace: monospace;
$font-color: $type-grey-dark;
$font-color-body: $type-grey-medium;
$font-color-input: $type-grey-dark;

//Header
$header-title-fontsize: 41px;
$header-title-fontsize-small: 32px;

//Font
$font-size-h1: 30px;
$font-family-h1: $font-family-headline;
$line-height-h1: 36px;

$font-size-h2: 24px;
$font-family-h2: $font-family-headline;
$line-height-h2: 30px;

$font-size-h3: 20px;
$font-family-h3: $font-family-headline;
$line-height-h3: 26px;

$font-size-h4: 16px;
$font-family-h4: $font-family-sans-serif;
$line-height-h4: 22px;

$font-size-h5: 14px;
$font-family-h5: $font-family-sans-serif;
$line-height-h5: 20px;

$font-size-t1: 13px;
$font-family-t1: $font-family-sans-serif;
$line-height-t1: 20px;

$font-size-t2: 12px;
$font-family-t2: $font-family-sans-serif;
$line-height-t2: 18px;

$font-size-t3: 11px;
$font-family-t3: $font-family-sans-serif;
$line-height-t3: 18px;

$font-size-large: 56px;
$font-size-very-large: 80px;

$font-size-small: 11px;
$font-size-very-small: 9px;

$font-size-claim: 22px;

//Buttons
$button-size-small: 24px;
$burger-button-height: 36px;
$burger-button-width: 56px;

//Border
$border: 1px solid $grey;
$border-dark: 1px solid $grey-dark;
$border-medium: 1px solid $grey-medium;
$border-white: 1px solid $divider-white;

//Shadow
$box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.13);
$box-shadow-dark: 3px 3px 5px 0 rgba(0, 0, 0, 0.45);

//Tooltip
$tooltip-width: 380px;
$tooltip-background-color: $mobile-dark-background;
$tooltip-font-color: #fff;

//Spinner
$spinner-size: 16px;
$spinner-size-big: 32px;
$cross-size: 10px;

//Navigation
$sub-menu-width: 334px;
$main-sub-menu-width: 200px;

//Form
$form-textarea-height: 100px;

// Expander container link
$expander-align-20: 20px;
$expander-align-10: 10px;
$expander-align-12: 12px;

//Navigation colors
$header-footer-bg-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
$sub-menu-bg-color: #fff;
$menu-border: $border;
$menu-border-white: $border-white;
$menu-box-shadow-white: inset 0 1px 0 0 $divider-white;
$menu-box-shadow: 1px 0 0 0 $grey;
$menu-font-color: $type-grey-medium;
$sub-menu-font-color: $type-grey-medium;
$menu-item-hover-bg-color: #fff;
$menu-item-hover-font-color: $action-blue;
$logo-border: $border;
$logo-box-shadow: inset 1px 0 0 $divider-white;
$sign-out-link-color: $action-blue;

//Key visual
$key-visual-bg-color: $springer-logo-blue;
$key-visual-bg-transparency: 0.68;
$key-visual-bg-transparency-light: 0.3;
$key-visual-bg-transparency-dark: 0.8;

//Freely accessible
$freely-accessible-color: $springer-logo-blue;
$freely-accessible-attribute-text: 'FREI';

//Footer
$springer-nature-logo-footer: url("../../images/springer/springer_nature_logo.svg");

//medication search result
$square-size: 12px;
$search-font-size: 10px;
$search-padding-right: 30px;
$search-padding-left: 20px;

// Scroll to top
$scroll-icon-size: 40px;
$border-radius: 50%;

// Interaction Rating severity color
$rating-severity-color1: #005096;
$rating-severity-color2: #3C96DC;
$rating-severity-color3: #546ACC;
$rating-severity-color4: #968CC8;
$rating-severity-color5: #D2C8F0;
$rating-severity-color6: #00AAA0;
$rating-severity-color7: #82A096;
$rating-severity-color8: #96DCD2;

// Teaser cards color
$teaser-flexbox-item-min-height: 240px;
$teaser-flexbox-color-1: #00AAA0;
$teaser-flexbox-color-2: #3C96DC;
$teaser-flexbox-color-3: #82A096;
