table:not(.borderless) {
    border: $border;

    tr th, tr td {
        padding: var(--spacing-xxs) 6px;
        border-right: $border;
    }

    td:last-child, th:last-child {
        border-right: none;
    }

    tr:not(:first-child), thead ~ tbody tr:first-child {
        th, td {
            border-top: $border;
        }
    }
}

table {
    tr th, tr td {
        text-align: center;
        font-size: $font-size-t1;
        color: $type-grey-medium;
    }

    tr th {
        font-weight: bold;
    }

    thead {
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
    }

    input[type="radio"] {
        margin-bottom: 0;
    }

    &.fixed {
        table-layout: fixed;
    }
}

table.align-left {
    tr th, tr td {
        text-align: left;
    }
}

table .spacer {
    background-color: transparent;
    height: var(--spacing);
}

/**
  Responsive: Tabelle ist nach rechts scrollbar
**/

// Parent der Table
.table-responsive-scroll-outer {
    @media screen and (max-width: $responsive-table-breakpoint) {
        overflow-x: auto;
    }
}

table.responsive-scroll {
    tbody {
        tr:nth-of-type(odd) {
            background-color: $gradient-grey-light-light;
        }

        tr:nth-of-type(even) {
            background-color: #fff;
        }

        tr.grid-row-titles {
            background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);

            th {
                border-top: none;
            }
        }
    }

    @media screen and (max-width: $responsive-table-breakpoint) {
        min-width: $responsive-table-breakpoint;
    }
}

tr.grid-row-cells td {
    word-break: break-word;
}

.grid table td.align-center {
    text-align: center;
}
