table.metabolic-effects-table {
    table-layout: fixed;

    tr:nth-child(2) {
        th:last-child {
            border-right: $border;
        }
    }

    tr {
        background: none transparent;
        margin-bottom: 0;
    }

    td img {
        vertical-align: -1px;
    }

    td.clinically-relevant {
        background-color: $clinically-relevant-color;
        color: $font-color-body;
    }

    td.primary-enzyme {
        background-color: $primary-enzyme-color;
        color: $font-color-body;
    }

    tbody tr td {
        &:nth-child(2n) {
            border-right: none
        }

        &:first-child {
            text-align: left;
            padding-left: var(--spacing-xs);
        }
    }

    col.agent-name {
        width: $cyp-agent-name-width;
    }

    &.responsive-scroll {
        @media screen and (max-width: $responsive-table-breakpoint) {
            min-width: $responsive-table-breakpoint;
        }
    }
}

@media screen and (max-width: $medium-breakpoint) {
    table.metabolic-effects-table tr {
        background: none transparent;
    }
}
