@charset "utf-8";
@import "bot_protection";
@import "cookie_banner";

a {
    color: $action-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:focus, &:active {
        color: $active-link;
        text-decoration: underline;
    }
}

::-moz-focus-inner {
    border: 0;
}

.seperator {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: $border;
    padding-bottom: var(--spacing);
}

.seperator-arrow {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: $border;

    .down-arrow {
        position: absolute;
        z-index: 999;
        top: -18px;
        bottom: 0px;
        height: 40px;
        width: 50px;
        cursor: pointer;
        background-image: url("../../images/userfrontend/down-arrow.svg");
        background-position: center center;
        background-repeat: no-repeat;
        //background-size: 3rem;
        left: 47.5%;

        &:hover {
            background-image: url("../../images/userfrontend/down-arrow-hover.svg");
        }
    }
}

.seperator-horizontal {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: $border;
    padding-bottom: var(--spacing-s);
    margin-left: -40px !important;
}

.seperator-horizontal-bottom {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: $border;
    //border-bottom: 1px solid $accent-orange;

    margin-right: 20px !important;
}

.seperator-double {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 4px double $grey-dark;
    margin-left: calc(var(--side-space) * -1);
    margin-right: calc(var(--side-space) * -1);
}

p {
    font-size: $font-size-t1;
    font-family: $font-family-t1;
    line-height: $line-height-t1;
    word-wrap: break-word;
}

pre {
    margin: 0;
}

.clearfix {
    height: 0;
}

.clearfix:after {
    clear: both;
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}

.hide {
    display: none;
}

.right {
    float: right !important;
}

.left {
    float: left !important;
}
