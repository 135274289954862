div.info, div.success, div.warning, div.error, span.error, small.error, .error-message {
    padding: var(--spacing-s) var(--spacing) var(--spacing-s) 45px;
    margin-bottom: var(--spacing);
    background-position: var(--spacing-s) calc(var(--spacing-s) + 2px);
    background-repeat: no-repeat;
}

div.error, span.error, small.error, .error-message {
    background-color: lighten($alert-color, 50%);
    background-image: url("../../images/userfrontend/alert_error.svg");
    border: 1px solid $alert-color;
    color: $type-grey-medium;
    font-style: normal;
    display: block;
}

div.info {
    background-color: $blue-light;
    background-image: url("../../images/userfrontend/alert_info.svg");
    border: 1px solid $info-color;
}

div.success {
    background-color: $green-light;
    background-image: url("../../images/userfrontend/alert_success.svg");
    border: 1px solid $success-color;
}

div.warning {
    background-color: $yellow-light;
    background-image: url("../../images/userfrontend/alert_warning.svg");
    border: 1px solid $warning-color;
}

//Positionierung der Flash-Messages
body > div.error, body > div.info, body > div.success, body > div.warning {
    position: absolute;
    width: 65%;
    top: 10%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
