.action-button {
    vertical-align: top;
    overflow: visible;
    display: block;
    border: none;
    text-align: left;
    padding: var(--spacing-xxs);

    &::before {
        content: "";
        display: inline-block;
        width: var(--action-button-icon-size);
        height: var(--action-button-icon-size);
        vertical-align: middle;
        background-color: transparent;
        background-size: var(--action-button-icon-size);
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: var(--spacing-xs);
    }

    &.edit::before {
        background-size: calc(var(--action-button-icon-size) - 3px);
    }
    &.print {
        cursor: pointer;
    }
}

.expander .action-button::before {
    background-size: var(--action-button-icon-size-small);
    margin-right: var(--spacing-xxs);
}

table td.action-button-container {
    width: 240px;
    margin-left: var(--spacing-l);

    ul li {
        text-align: left;
    }

    .action-button {
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    li:not(:last-child) .action-button {
        margin-bottom: var(--spacing-xxs);
    }
}

.action-button-container-outer, *:not(.action-button-container-outer) > .action-button-container:not(td) {
    @media only screen and (min-width: $small-breakpoint + 1) {
        z-index: 1000;
        &:not(.float) {
            position: absolute;
            display: inline-block;
            top: 0;
            right: var(--side-space);
        }

        &.float {
            float: right;
            margin-left: var(--spacing);
            margin-top: -$font-size-h1 / 2;
            margin-bottom: 0;
        }
    }
}

.expander {
    .action-button-container-outer, *:not(.action-button-container-outer) > .action-button-container:not(td) {
        @media only screen and (min-width: $small-breakpoint + 1) {
            &.float {
                right: calc(var(--spacing-xs) * -1);
                top: calc(var(--side-space) * -1 + var(--spacing-s));
            }
        }
    }
}

.info-container {
    box-shadow: $box-shadow;
    padding: var(--spacing-xs);
    border: $border-dark;
}

.action-button-container:not(td) {
    margin-bottom: var(--spacing);
    box-shadow: $box-shadow;
    border: 1px solid $action-blue;;
    background-color: $action-blue-light-light;
    padding: var(--spacing-xs);

    .action-button {
        display: flex;
        justify-content: flex-start;
        line-height: calc(var(--action-button-icon-size) - 1px);
        text-decoration: none;
        color: $type-grey-medium;
        border-radius: 8px;

        $buttonColor: #fff;

        &:hover {
            background-color: $buttonColor;
            box-shadow: $box-shadow-dark;
            color: $action-blue;
        }

        &:focus, &:active {
            background-color: darken($buttonColor, 3);
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.35);
            transform: translate(1px, 1px);
            color: $action-blue;
        }

        @media (max-width: $small-breakpoint) {
            background-position: center;
            transition: background 0.8s;

            &:hover {
                background: $buttonColor radial-gradient(circle, transparent 1%, darken($buttonColor, 5) 1%) center/15000%;
            }

            &:active {
                background-color: $buttonColor;
                background-size: 100%;
                transition: background 0s;
            }
        }
    }

    .action-button:not(:last-child) {
        margin-bottom: var(--spacing-xs);
    }

    .metadata {
        width: 100%;
    }
}
.expander-button-container {
    box-sizing: initial;
    padding-left: $expander-align-20 !important;
    width: 100%;
    display: block;
    margin-left: $expander-align-20;

    .row {
        margin-bottom: 0px;
        padding-left: $expander-align-10;
    }
}
.expander-button {
    display: table-cell !important;
    padding-right: $expander-align-12 !important;
}
