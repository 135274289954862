.page-context-menu {
    z-index: 1;
    position: absolute;
    right: calc(var(--spacing) + 1px);
    top: 0;
}

.expander .page-context-menu {
    right: var(--spacing-xs);
    top: var(--spacing-xs);
}
