.view-filter {
    overflow: auto;

    * {
        margin-bottom: var(--spacing);
        float: left;
        padding: var(--view-filter-height-padding) var(--spacing-s);
        background-color: #fff;
        color: $type-grey-medium;
        border-top: $border;
        border-bottom: $border;
        border-right: $border;
        font-weight: bold;
    }

    div {
        padding-top: calc(var(--view-filter-height-padding) - 1px);
    }

    a:hover {
        background-color: $gradient-grey-light-dark;
        color: $type-grey-medium;
        text-decoration: none;
    }

    a:focus {
        background-color: $gradient-grey-dark-dark;
        color: $type-grey-medium;
        text-decoration: none;
    }

    :last-child {
        border-top-right-radius: var(--view-filter-border-radius);
        border-bottom-right-radius: var(--view-filter-border-radius);
    }

    :first-child {
        border-left: $border;
        border-top-left-radius: var(--view-filter-border-radius);
        border-bottom-left-radius: var(--view-filter-border-radius);
    }

    .active-view {
        background-color: $accent-orange;
        border-color: $accent-orange;
        color: #fff;
    }
}

@media only screen and (max-width: $small-breakpoint) {
    .view-filter {
        height: inherit;
        margin-bottom: var(--spacing);

        * {
            margin: 0;
            float: none;
            display: block;
            padding: var(--view-filter-height-padding-small) var(--spacing-xs);
            border-bottom: $border;
            border-left: $border;
            border-right: $border;
            border-top: none;
        }

        :first-child {
            border-top: $border;
            border-top-right-radius: var(--view-filter-border-radius);
            border-top-left-radius: var(--view-filter-border-radius);
            border-bottom-left-radius: 0;
        }

        :last-child {
            border-bottom-right-radius: var(--view-filter-border-radius);
            border-bottom-left-radius: var(--view-filter-border-radius);
            border-top-right-radius: 0;
        }

        div {
            padding-top: calc(var(--view-filter-height-padding-small) - 1px);
        }
    }
}
