.has-tip {
    position: relative;
    cursor: help;
}

.has-tip:after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 8px;
    margin-top: 5px;
    opacity: 0;
    border-radius: 6px;
    padding: var(--spacing-xs);
    transition: all 0.4s ease;
    background: $tooltip-background-color;
    color: $tooltip-font-color;
    max-width: $tooltip-width;
    min-width: 150px;
    z-index: 100;
    font-size: $font-size-t3;
    pointer-events: none;
    text-align: center;
}

.has-tip:before {
    content: "";
    position: absolute;
    top: 100%;
    opacity: 0;
    left: var(--spacing);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $tooltip-background-color;
    z-index: 100;
    pointer-events: none;
}

.has-tip:hover:before, .has-tip:hover:after {
    opacity: 1;
}
