.blurb {
    box-shadow: $box-shadow;
    display: inline-block;
    line-height: 0;
    padding: var(--spacing-s);
    border: $border;

    @include badge;

    .badge {
        position: absolute;
        bottom: calc(var(--spacing) - 3px);
        right: 0;
        margin-right: -6px;
        margin-bottom: -3px;
        a {
            font-family: $font-family-h4;
            font-size: $font-size-h4;
            line-height: $line-height-h4;
            color: #fff;
            padding: 6px 25px 6px 10px;
            display: inline-block;

            &:hover {
                text-decoration: none;
            }

            &::after {
                content: '';
                width: 10px;
                height: 14px;
                display: block;
                background-image: url("../../images/userfrontend/chevron_right.svg");
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 11px;
                right: 8px;
            }
        }
    }

    .book-cover {
        display: inline-block;

        & > a {
            line-height: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &.banner {
        .description {
            font-family: $font-family-serif;
            font-size: $font-size-t2;
            line-height: $line-height-t2;
            color: $type-grey-dark;

            h3 {
                margin-bottom: var(--spacing-xs);
            }

            a:hover {
                text-decoration: none;
            }

            .read-more {
                text-align: right;

                a:hover {
                    text-decoration: underline;
                }

                a::before {
                    content: '\00BB\0020';

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.banner-new-book-padding{
    padding-bottom:23px !important;
}

.banner-old-book-padding{
    padding-bottom:50px !important;
}
