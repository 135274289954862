.kompendium-news {
    ul {
        @include list-no-bullets();
        margin-bottom: var(--spacing-xxs);

        li {
            padding: var(--spacing-xxs) 0;
            border-bottom: $border;

            &:before {
                content: "»";
                display: inline-block;
                color: $action-blue;
                width: 7%;
                vertical-align: top;
            }

            a {
                display: inline-block;
                width: 93%;
            }

        }

        li:first-child {
            border-top: $border;
        }

        li:last-child {
            border-bottom: none;
        }
    }
}
