.entity-search-list {
    .columns-list {
        margin: 0 auto;
        width: 100%;
        position: relative;

        @media only screen and (min-width: $medium-breakpoint + 1) {
            > *:nth-child(3n + 1) {
                border-right: $border;
            }

            > *:nth-child(3n + 2) {
                border-right: $border;
                padding-left: var(--spacing-xs);
            }

            > *:nth-child(-n + 3) {
                padding-top: var(--spacing-xs);
            }
        }

        border-bottom: $border;
    }

    // Mobile
    @media only screen and (max-width: $medium-breakpoint) {
        margin-right: calc(-1 * var(--side-space));
        margin-left: calc(-1 * var(--side-space));

        .columns-list {
            border-bottom: none;
        }

        .columns-list > div {
            border-bottom: $border;
            padding-bottom: 0 !important;

            > a {
                display: inline-block;
                height: var(--spacing-l);
                padding-top: 10px;
                width: 100%;
                padding-left: var(--spacing);

                &[data-name]::after {
                    content: attr(data-name);
                    display: inline-block;
                    font-size: $font-size-small;
                    color: $type-grey-medium;
                    padding-right: var(--spacing);
                }
            }

            &:first-child {
                border-top: $border;
            }

            a:focus, a:active {
                color: $active-link;
                text-decoration: none;
            }
        }

        &:not(.table) {
            .columns-list:before {
                content: attr(id);
                display: block;
                padding-left: var(--spacing);
                background-color: $mobile-dark-background;
                border-bottom: 1px solid $mobile-border-bottom;
                border-top: 1px solid $mobile-border-top;
                color: #fff;
                font-size: $font-size-h4;
                padding-top: 7px;
                padding-bottom: 5px;
                font-weight: bold;
            }

            .columns-list:first-child {
                padding-top: 0;
                margin-top: var(--spacing);
            }

            a {
                small {
                    color: $type-grey-medium;
                }
            }
        }

        > div:not(.columns-list) {
            margin: 0 var(--spacing);
        }
    }
}
