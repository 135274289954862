table.pharmacodynamic-table {
    table-layout: fixed;

    tbody tr td:first-child {
        text-align: left;
        padding-left: var(--spacing-xs);
    }

    &.responsive-scroll {
        @media screen and (max-width: $responsive-table-breakpoint) {
            min-width: $responsive-table-breakpoint;
        }
    }
}
