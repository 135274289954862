.key-visual {
    width: 100%;
    height: 239px;
    overflow: hidden;
}

.key-visual-text-outer {
    @keyframes slide-in-from-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    animation: slide-in-from-right 1.5s ease-in-out 0s 1 forwards;
    background-color: transparent;
    height: 100%;
    width: 420px;
    padding-right: var(--spacing);
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $small-breakpoint) {
        width: auto;
        padding: var(--spacing-s);
    }
}

h2.claim {
    margin: var(--spacing) 0 0 0;

    span {
        vertical-align: middle;
        text-align: center;
        color: #fff;
        display: block;
    }
}

@media only screen and (min-width: $small-breakpoint + 1) {
    .key-visual-overlay:first-of-type {
        @keyframes skew {
            from {
                transform: skewX(-2deg);
            }
            to {
                transform: skewX(-10deg);
            }
        }
        animation: skew 1.5s ease-in-out 0s 1 forwards;

        background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency);

        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }

    .key-visual-overlay:nth-of-type(2) {
        @keyframes skew2 {
            from {
                transform: skewX(-30deg);
            }
            to {
                transform: skewX(-15deg);
            }
        }
        animation: skew2 2s ease-in-out 0s 1 forwards;
        background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency-light);
        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }
}

@media only screen and (max-width: $small-breakpoint) {
    .key-visual-overlay:first-of-type {
        background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency);
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .key-visual-overlay:nth-of-type(2) {
        @keyframes skew2 {
            from {
                transform: skewX(-30deg);
            }
            to {
                transform: skewX(-15deg);
            }
        }
        animation: skew2 2s ease-in-out 0s 1 forwards;
        background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency-light);
        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }
}

.key-visual-text-outer-image {
    @keyframes slide-in-from-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    animation: slide-in-from-right 1.5s ease-in 0s 1 forwards;
    background-color: transparent;
    height: 40%;
    width: 500px;
    padding-left: var(--spacing);
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 999;

    @media only screen and (max-width: $small-breakpoint) {
        width: auto;
        padding: var(--spacing-s);
    }
}

h2.claim-tag {

    span {
        vertical-align: middle;
        text-align: left;
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: bold;
        z-index: 99;
        background-color: rgba(223, 238, 244, 0.6);
        border: 1px solid white;
        border-radius: 10px;
        padding-left: var(--spacing-xs);
    }

    @media only screen and (max-width: $small-breakpoint) {
        margin: var(--spacing-s) 0 0 0;
    }
}

.key-visual-text-outer-count {
    background-color: rgba(223, 238, 244, 0.6);
    border: 1px solid white;
    border-radius: 10px;
    height: 35%;
    width: 280px;
    padding-left: var(--spacing-xs);
    left: var(--spacing);
    top: 40%;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: left;

    .stoerer-count {
        pointer-events: none;
        padding-top: 4px;

        .stoerer-count-text {
            text-align: left;
            color: #000;
            font-family: 'Lato', sans-serif;
            font-size: $font-size-h4;
            line-height: $line-height-h4;
        }
    }
}

.key-visual-text-outer-button {
    left: var(--spacing);
    top: 80%;
    position: absolute;
    z-index: 100;
}

