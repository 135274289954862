.form-buttons-bottom {
    @include flex();

    .flex-order-first {
        order: -1;
    }

    .flex-order-last {
        order: 99;
    }

    .flex-order-last-second
    {
        right: calc((-2 * var(--spacing-xxl)) - var(--spacing-xl));
    }

    @media only screen and (min-width: $small-breakpoint + 1) {
        margin-left: calc(-1 * var(--spacing-xs));

        > * {
            margin: 0 0 0 var(--spacing-xs);
        }

        align-items: baseline;

        .flex-order-last {
            margin-left: auto;
        }

        .flex-order-last-second
        {
            right: calc((-2 * var(--spacing-xxl)) - var(--spacing-xl));
        }
    }

    @media only screen and (max-width: $small-breakpoint) {
        flex-direction: column-reverse;

        > *:not(.flex-order-last):not(.flex-order-first):not(.flex-order-last-second) {
            margin-top: var(--spacing-xs);
        }

        .flex-order-first {
            margin-top: var(--spacing-s);
        }

        .flex-order-last-second
        {
            margin-top: var(--spacing-s);
            right:0px;
        }
    }
}
