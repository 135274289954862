form {
    &[data-dirty] {
        .form-is-dirty-hint {
            display: block;
        }
    }

    .form-is-dirty-hint {
        display: none;
        position: fixed;
        width: var(--form-is-dirty-hint-icon-size);
        height: var(--form-is-dirty-hint-icon-size);
        background-image: url("../../images/interactionauthor/warning.svg");
        background-repeat: no-repeat;
        background-size: var(--form-is-dirty-hint-icon-size);
        top: 30%;
        right: var(--spacing);
        cursor: pointer;

        @media only screen and (max-width: $form-is-dirty-hint-breakpoint) {
            top: calc(-1 * var(--spacing-s));
            right: 0;
            position: absolute;
        }

        z-index: 10000;
    }

    &[data-dirty] {
        .form-is-dirty-hint-countdown-text {
            display: block;
        }
    }

    .form-is-dirty-hint-countdown-text
    {
        color : $alert-color;
        width : calc(2 * var(--spacing-xxl));
        font-size: $font-size-t3;
        font-weight: 600;

        display: none;
        position: fixed;
        height: var(--form-is-dirty-hint-icon-size);
        top: 31%;
        right: calc(2 * var(--spacing) - 8px);

        @media only screen and (max-width: $form-is-dirty-hint-breakpoint) {
            top: calc(-1 * var(--spacing-s));
            right: 10px;
            position: absolute;
        }

        z-index: 10000;
    }
}
