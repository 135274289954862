div.publishable {
    width: 100%;
    display: inline-block;
    text-align: center;
    background-size: var(--spacing);
    background-position: var(--spacing-s) center;

    &.success {
        background-image: url("../../images/interactionauthor/book.svg");
    }

    &.warning {
        background-image: url("../../images/interactionauthor/book_bookmarked.svg");
    }
}
