.documentchooser {
    input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    input[type="file"] + label {
        padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) calc(var(--spacing-s) * 2 + 42px);
        display: inline-block;
        cursor: pointer;
        color: $font-color-input;
        border-radius: 2px;
        border: $border-dark;
        background: transparent url("../../images/fileupload/cloud_upload.svg") var(--spacing-s) center / 40px no-repeat;
    }

    input[type="file"].document-chosen + label {
        background-image: url("../../images/fileupload/cloud_check.svg");
    }

    input[type="file"]:focus + label,
    input[type="file"] + label:hover {
        border: 1px solid $accent-orange;
        background-color: rgba(238, 125, 17, 0.3);
    }

    input[type="file"].document-chosen + label {
        border: 1px solid $success-color;
        background-color: rgba(227, 251, 186, 0.3);
    }

    input[type="file"].document-chosen.has-focus + label {
        outline: 1px solid $success-color;
    }
}
