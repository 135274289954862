@media print {
    header, footer, form, a.small-action-button, .page-context-menu, .tabs, .cookie-banner, .interaction-contact-list-display {
        display: none !important;
    }

    .expander > .inner {
        margin-bottom: var(--spacing);
        .spinner {
            display: none;
        }
        .action-button-container {
            margin-left: -20px;
        }
    }

    .expander > .title {
            font-weight: bold;
    }

    .page-wrapper {
        max-width: unset;
    }

    .column, .columns, .info-box {
        padding: 0;
    }

    .row, .row .row {
        margin-left: 0;
        margin-right: 0;
    }

    .page-wrapper::after {
        content: "Bitte besuchen Sie www.psiac.de für aktuelle Informationen.";
        display: block;
    }

    #print-preview-footer {
        bottom: 0px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-t2;
        line-height: $line-height-t3;
        color: $type-grey-medium;
        padding-top: 20px;
    }
    #print-preview-border {
        overflow: hidden;
        border: 2px solid $grey-dark;
        padding: 0 10px 0 10px;
        background: none !important;
    }

    @-moz-document url-prefix() {
        body {
            background: none !important;
        }
    }

    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
        color-adjust: exact !important; /*Firefox*/
    }

    @page {size: landscape}
}
