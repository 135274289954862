input::-ms-clear {
    display: none;
}

$placeholder-color: $type-grey-light;
input::placeholder {
    color: $placeholder-color;
}

input::-webkit-input-placeholder {
    color: $placeholder-color;
}

input::-ms-input-placeholder {
    color: $placeholder-color;
}

textarea:placeholder-shown {
    color: $placeholder-color;
}

textarea::placeholder {
    color: $placeholder-color;
}

textarea::-webkit-input-placeholder {
    color: $placeholder-color;
}

:-ms-input-placeholder {
    color: $placeholder-color !important;
}

textarea::-ms-input-placeholder {
    color: $placeholder-color;
}

select[disabled] {
    background-color: $grey-medium;
    cursor: default;
}

ul.form-list {
    @include list-no-bullets();

    li {
        > * {
            border-radius: 2px;
            border: $border-dark;
            padding: var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xs);
            display: flex;

            *:first-child {
                padding: var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs) 0;
                flex: 1 0;
            }

            .remove-item-btn {
                align-self: center;
                flex-basis: 24px;
            }
        }
    }

    li.error > div {
        background: lighten($alert-color, 50%);
        border: 1px solid $alert-color;
        color: $font-color-body;
        font-style: normal;
    }
}

input[type="checkbox"] {
    vertical-align: -1px;
}

input.with-appended-unit {
    width: auto;
    text-align: right;
    $unitPosRight: 47px;

    & + span {
        color: $type-grey-light;
        position: relative;
        right: $unitPosRight;
        pointer-events: none;
    }

    &:not(:placeholder-shown):not(.unit-empty) {
        padding-right: $unitPosRight + 3px;
    }

    &:placeholder-shown {
        & + span {
            display: none;
        }
    }
}

.remove-item-btn {
    height: $cross-size;
    width: $cross-size;
    background-size: $cross-size;
    background: transparent url("../../images/userfrontend/cross_grey.svg") no-repeat center center;
    float: right;
    cursor: pointer;

    &:hover {
        background-image: url("../../images/userfrontend/cross_grey_dark.svg");
    }
}

#interaction_inquiry {
    .option-choice {
        display: inline-block;
        vertical-align: 2px;
        font-weight: normal;
    }

    .query-choice {
        @include flex();

        input[type="radio"] {
            margin-top: 12px;
            margin-right: 5px;
        }
    }
}

.explanation {
    font-style: italic;
    font-size: $font-size-t3;
    color: $type-grey-light;
    font-weight: normal;

    a {
        font-size: $font-size-t3;
    }
}

label.disabled {
    color: $type-grey-light;
    cursor: default;

    input {
        cursor: default;
    }
}

.nested-checkable-input {
    cursor: pointer;
    display: table;
}

input[type="checkbox"], input[type="radio"] {
    vertical-align: -2px;
    margin: 0 var(--spacing-xxs) 0 0;

    &:not(.mb) {
        margin-bottom: var(--spacing-xxs);
    }
}

input[type="checkbox"] + label, input[type="radio"] + label {
    vertical-align: baseline;
}

.form-pathway-reference-textarea {
    min-height: 0;
    height: 34px;
}

//Fehlermeldungen vom Server
.server-error {
    background: lighten($alert-color, 50%) url("../../images/userfrontend/alert_error.svg") no-repeat center left var(--spacing-s);
    border: 1px solid $alert-color;
    color: $font-color-body;
    font-style: normal;
    padding: var(--spacing-s) var(--spacing) 13px 45px;
}

input {
    $errorBorder: 1px solid $alert-color;

    &:not([type="radio"]).error {
        border: $errorBorder;
    }

    &[type="radio"].error {
        outline: $errorBorder
    }
}

#metabolic_transformation {
    .medium-block-grid-3 {
        > * {
            margin: 0 !important;
        }
    }
}
