button, .button {
    pointer-events: auto;
    text-decoration: none;
    background-color: $info-color;
    background-image: linear-gradient(to bottom, $info-color, $action-blue);
    padding: var(--spacing-xs) var(--spacing-xs);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    text-align: center;
    color: #fff;
    line-height: normal;
    display: inline-block;
    font-size: $font-size-t1;
    outline: none;

    &.narrow {
        padding: 5px var(--spacing-xs);
        vertical-align: -3px;
    }

    @media (min-width: $small-breakpoint + 1) {
        &:hover {
            text-decoration: none;
            background-color: $info-color;
            background-image: linear-gradient(to bottom, $info-color, $action-blue 50%);
        }

        &:focus {
            background-color: #246997;
            background: linear-gradient(to bottom, #246997 0%, #2086ca 50%, #2086ca 50%, #0266a8 100%);
            text-decoration: none;
        }

        &:active {
            text-decoration: none;
        }
    }

    @media (max-width: $small-breakpoint) {
        background-position: center;
        transition: background 0.8s;

        &:hover {
            background: $action-blue radial-gradient(circle, transparent 1%, $action-blue 1%) center/15000%;
        }

        &:active {
            background-color: $action-blue-light;
            background-size: 100%;
            transition: background 0s;
        }
    }

    &.disabled, &[disabled="disabled"], &[disabled] {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
    }

    &.grey {
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
        border: $border;
        color: $type-grey-dark;

        &:hover {
            background-color: $gradient-grey-light-light;
            background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark 25%);
            text-decoration: none;
        }

        &:focus {
            background-color: $gradient-grey-light-light;
            background: linear-gradient(to bottom, $gradient-grey-light-light 0%, $gradient-grey-light-dark 50%, $gradient-grey-light-dark 50%, $gradient-grey-light-light 100%);
        }
    }

    &.red {
        background-color: #ff0808;
        background-image: linear-gradient(to bottom, #ff0808, #c40606);
        color: #fff;

        &:hover {
            background-color: #ff0808;
            background-image: linear-gradient(to bottom, #ff0808, #c40606 50%);
            text-decoration: none;
        }

        &:focus {
            background: linear-gradient(to bottom, #c43737 0%, #e54040 50%, #e54040 50%, #c43737 100%);
        }
    }

    &.spinner {
        &::before {
            content: "";
            width: $spinner-size;
            height: $spinner-size;
            display: inline-block;
            background: transparent url("../../images/userfrontend/spinner.svg") no-repeat center center;
            background-size: $spinner-size;
            vertical-align: -3px;
            margin-right: 7px;
        }
    }

    &.login-button {
        color: #fff !important;
        line-height: unset;
        padding: 6.8px 7px;
        vertical-align: 1px;
        &:hover {
            text-decoration: none;
            color: #fff !important;
        }

        &:focus {
            color: #fff !important;
        }
    }
}

button:not([type]) {
    float: right;
    vertical-align: 1px;
}
