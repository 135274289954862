button.create-reference {
    padding: var(--spacing-xxs) var(--spacing-xs);
    background-color: $gradient-grey-light-light;
    background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
    border: $border;
    color: $type-grey-dark;

    &:hover {
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark 25%);
        text-decoration: none;
    }

    &:focus {
        background-color: $gradient-grey-light-light;
        background: linear-gradient(to bottom, $gradient-grey-light-light 0%, $gradient-grey-light-dark 50%, $gradient-grey-light-dark 50%, $gradient-grey-light-light 100%);
    }

    &:before {
        content: "";
        display: inline-block;
        width: var(--action-button-icon-size);
        height: var(--action-button-icon-size);
        vertical-align: middle;
        background-color: transparent;
        background-size: var(--action-button-icon-size);
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../../images/interactionauthor/books.svg");
        margin-right: var(--spacing-xs);
    }
}

.pubmed-link {
    white-space: nowrap;
}

.reference-item {
    > input {
        float: left;
        margin-top: 6px;
        margin-right: var(--spacing-xxs);
    }

    .title {
        @include flex();

        > a {
            margin-left: auto;
            margin-right: 3px;
            padding-left: 3px;
            font-size: xx-small;
            line-height: 14px;
            align-self: flex-start;
        }

        .info {
            &.id {
                color: $accent-orange;
            }

            font-size: xx-small;
            align-self: flex-start;
            margin-right: 3px;

            * {
                font-size: inherit;
                line-height: 12px;
            }

            &:first-child {
                margin-left: auto;
                padding-left: 3px;
            }
        }
    }

    .has-attribute {
        margin-left: var(--spacing-xxs);
    }
}

.reference-search-container {
    @include flex();

    > div:first-child {
        flex-grow: 1;
        margin-right: var(--spacing-xxs);
    }

    > div:nth-child(2) {
        button {
            margin-top: 1px;
        }
    }
}

.references-container {

    .reference-display-text {
        color: $type-grey-dark;
        border-bottom: 1px solid $type-grey-dark;
        max-width: 400px;
    }

    .reference-group-comment-button {
        font-size: $font-size-t3;
        line-height: $line-height-t3;
        border-radius: 0;
        border: $border-dark;
        border-top: none;
        cursor: pointer;
        padding: 0 var(--spacing-xxs) 2px var(--spacing-xxs);
        position: absolute;
        bottom: 0;
        left: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    .reference-group-comment {
        display: none;
        resize: vertical;
        font-size: $font-size-t3;
        line-height: $line-height-t3;
        margin-top: -23px;
        border-top: none;
    }

    .reference-buttons {
        float: right;
        height: inherit;

        .remove-reference {
            font-size: $font-size-t3;
            line-height: 0;
            height: 18px;
            width: 15px;
            border-radius: 0;
            top: -2px;
            border-top: 0;
            cursor: pointer;
            margin-right: var(--spacing-xs);
            padding: 0;
        }
    }
}

*:not(.metabolic-effects-table) {
    .references-container {
        > div {
            display: inline-block;
            @include flex();
            flex-wrap: wrap;

            > div:nth-child(2) {
                order: 2;
                min-width: 146px;
            }

            > div:nth-child(3) {
                order: 1;
                flex-basis: 255px;
                margin: var(--spacing-xs) var(--spacing-s) 0 0;

                input {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.metabolic-effects-table {
    .reference-text-container {
        text-align: justify;
    }
}

#merge-reference-form {
    label {
        font-weight: normal;
    }
}

.link-to-external-reference {
    $size: 11px;
    width: $size;
    height: $size;
    background: transparent url("../../images/userfrontend/external_link.svg") no-repeat 0 0;
    background-size: $size;
    display: inline-block;
    margin-left: 1px;
    vertical-align: -1px;

    &:hover {
        opacity: 0.5;
    }
}

.expandable-filter {
    label.nested-checkable-input {
        display: block;

        &:not(:last-of-type) {
            margin-bottom: var(--spacing-xs);
        }
    }

    #filter_types label {
        margin-bottom: var(--spacing);
    }
}

.references-list {
    li, a {
        font-size: $font-size-t3 !important;
        line-height: $line-height-t3 !important;
    }

    .link-to-external-reference {
        vertical-align: -2px;
    }

    > li:not(:last-child) {
        border-bottom: $border;
        margin-bottom: var(--spacing-xxs);
        padding-bottom: var(--spacing-xxs);
    }

    > li > span:not(.info) {
        font-weight: bold;
    }
}

#toggle-advanced-search {
    float: right;
    margin-right: var(--spacing-s);
    height: 33px;

    &:after {
        $icon-size: 13px;
        content: "";
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        vertical-align: middle;
        background-color: transparent;
        background-size: $icon-size;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: var(--spacing-xs);
    }

    &.advanced-search:after {
        background-image: url("../../images/userfrontend/magnifier-marquee.svg");
    }

    &.search:after {
        background-image: url("../../images/userfrontend/search.svg");
    }
}

