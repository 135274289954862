abbr {
    display: block;
}

span.has-attribute {
    margin: auto 0 auto 0;
    padding: 2px var(--spacing-xxs);
    background-color: $mobile-dark-background;
    color: #fff;
    border: 1px solid $mobile-border-top;
    font-size: $font-size-small;
    border-radius: 7px;
    font-family: $font-family-monospace;
    line-height: $font-size-small + 1;
}
span.has-attribute.auto:before {
    background-color: $mobile-dark-background;
    content: 'auto';
    padding: 2px 6px 2px 4px;
}
span.has-attribute.Stoffwechselwege:before {
    background-color: $mobile-dark-background;
    content: 'Stoffwechselwege';
    padding: 2px 6px 2px 4px;
}
span.has-attribute.Pharmakodynamik:before {
    background-color: $mobile-dark-background;
    content: 'Pharmakodynamik';
    padding: 2px 6px 2px 4px;
}

.expander .title span.has-attribute {
    font-size: $font-size-very-small;
    line-height: 14px;
    padding: 0 var(--spacing-xxs);
    margin: auto var(--spacing-xxs) 3px 0;

    &:nth-child(2) {
        margin-left: auto;
    }
}

.expander .title-auto span.has-attribute {
    font-size: $font-size-very-small;
    line-height: 14px;
    padding: 0 var(--spacing-xxs);
    margin: auto var(--spacing-xxs) 3px 0;

    &:nth-child(2) {
        margin-left: auto;
    }
}

span.has-attribute.freely-accessible {
    background-color: $freely-accessible-color;
    border: 1px solid $freely-accessible-color;

    &::before {
        background-color: $freely-accessible-color;
        content: $freely-accessible-attribute-text;
        padding: 3px 6px 3px 4px;
    }
}
