@charset "utf-8";

@import "login";
@import "entity_list";
@import "password_checker";
@import "authors";
@import "interactions";
@import "cyp";
@import "entity_search_list";
@import "datagrid";
@import "pharmacodynamic_table";
@import "interaction_query";
@import "interaction_contact";
@import "form";
@import "interaction_rating";
@import "description_box";
@import "metabolic_effects_table";
@import "interaction_metadata";
@import "references";
@import "view_filter";
@import "autocomplete";
@import "generated_interactions";
@import "action_button_icons";
@import "small_action_button";
@import "action_button";
@import "sticky_nav_bar";
@import "pathways";
@import "not_granted";
@import "attributes_table";
@import "explainer_video";
@import "tdm_tool";
@import "concatenated_list";
@import "version_stamp";
@import "interaction_form";
@import "app_guide";
@import "interaction_verdict";
@import "form_label_optional";
@import "form_bottom_buttons";
@import "double_prescriptions";
@import "user_settings";
@import "attribution";
@import "interaction_grid";
@import "interaction_diff";
@import "interaction_publishable_info";
@import "interaction_version_history_action";
@import "edit_pharmacology";
@import "form_is_dirty";
@import "top_action_button";
@import "top_buttons";
@import "old_ie_browser";
@import "favorite_star_icons";
@import "carousel_image_slider";
@import "copied";

dt {
    display: inline-block;
    margin-right: 5px;
}

dd {
    font-size: $font-size-t3;
    line-height: $line-height-t3;
    display: inline-block;
}

.payment-info {
    white-space: pre-line;
}

small {
    a {
        font-size: 100%;
    }
}

.clinically-relevant {
    color: $clinically-relevant-color;
}

.primary-enzyme {
    color: $primary-enzyme-color;
}

.close-reveal-modal {
    &:hover {
        text-decoration: none;
    }
}

.js-active-hidden ~ .js-active-visible {
    display: none;
}

.pika-single .pika-lendar {
    width: unset;
}

.pika-button {
    text-align: center !important;
}

.red-color-bold {
    font-weight: bold;
    color: red;
}
