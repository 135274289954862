.explainer-video-container {
    max-width: $explainer-video-width;
    border: $border;
    box-shadow: $box-shadow;
    padding: 3px 3px 0 3px;
    margin-top: -25px;
}

.landingpage .explainer-video-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-l);
}
