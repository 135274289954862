#login-form {
    box-shadow: $box-shadow;

    input[type="checkbox"] + label, input[type="radio"] + label {
        margin-right: 0;
    }

    > div {
        padding: var(--spacing-s);
    }

    > div:first-child {
        border: $border;

        input:not(last-child) {
            margin: var(--spacing-xxs) 0 var(--spacing-xs) 0;
        }
    }

    #remember_me {
        margin-bottom: 0;
    }

    .grey-area {
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
        border: $border;
        border-top: 1px solid $divider-white;
        text-align: right;

        button {
            float: none;
        }
    }
}
