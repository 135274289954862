@mixin badge($badge-color: $orange, $badge-color-light: $accent-orange, $curl-width: 6px) {
    .badge {
        $curl-height: 10px;
        background-color: $badge-color;
        background: linear-gradient(to bottom, $badge-color 0%, $badge-color-light 100%);
        border-radius: 0 6px 0 9px;
        box-shadow: -2px 2px 6px -1px rgba(0, 0, 0, 0.45),
        inset 1px 1px 0px 0px $badge-color,
        inset 1px 2px 0px 0px rgba(255, 255, 255, 0.45);

        .curl {
            $shadow-orange: darken($badge-color, 9%);
            position: absolute;
            right: 0;
            bottom: -$curl-height / 2;
            background-color: $badge-color-light;
            width: $curl-width;
            height: $curl-height / 2;

            &::after {
                content: "";
                display: block;
                background-color: $badge-color-light;
                width: $curl-width;
                height: $curl-height;
                border-bottom-right-radius: $curl-height;
                border-top-right-radius: $curl-height;
                $box-shadow-width: $curl-width / 3;
                $box-shadow-width-minux: -$curl-width / 3;
                box-shadow: inset $box-shadow-width-minux $box-shadow-width $box-shadow-width 0 $shadow-orange;

            }
        }
    }
}
