$border-radius: 6px 6px 0 6px;
.stoerer {
    background-color: transparent;
    position: absolute;
    right: 60px;
    z-index: 100;
    top: 72px;
    pointer-events: none;
    @include badge();

    .badge {
        border-radius: $border-radius;
    }

    .badge, .shine {
        transform: rotate(-90deg);
        height: 150px;
        width: 113px;
    }

    .stoerer-text {
        bottom: 120px;
        text-align: center;
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: $font-size-h2;
        line-height: $line-height-h2;
        text-transform: uppercase;
        letter-spacing: 1px;

        img {
            margin-top: 6px;
            width: $font-size-h2;
        }
    }

    @media only screen and (max-width: $small-breakpoint) {
        right: 40px;
        top: 49px;
        .badge, .shine {
            transform: rotate(-90deg);
            height: 110px;
            width: 83px;
        }
        .stoerer-text {
            bottom: 88px;
            font-size: $font-size-h4;
            line-height: $line-height-h4;

            img {
                margin-top: 2px;
                width: $font-size-h4;
            }
        }
    }
}

.landingpage .stoerer {
    @include badge($cta-color, $cta-color);
    top: 51px;
    @media only screen and (max-width: $small-breakpoint) {
        top: 56px;
    }

    .badge {
        border-radius: $border-radius;
    }
}

.shine {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    @keyframes shine {
        0% {
            top: -110%;
            left: -210%;
            opacity: 0;
        }
        100% {
            opacity: 1;
            top: -30%;
            left: -30%;
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
        background: transparent;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.0) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
        animation-name: shine;
        animation-duration: 0.9s;
        animation-timing-function: ease;
        animation-delay: 0.5s;
    }
}
