form:not(#login-form) {
    label:not(.required):not([type="required"]):not(.nested-checkable-input):not(.optional-hidden) {
        &:after {
            content: ' -optional-';
            color: $type-grey-light;
            font-weight: normal;
            font-size: $font-size-small;
        }
    }
}
