ul, ol {
    margin: var(--spacing-s) 0;
    padding: 0 0 0 var(--spacing-l);

    li {
        font-size: $font-size-t1;
        line-height: $line-height-t1;
    }

    li span.highlight {
        color: $highlight-color;
    }
}

ul.list-no-bullets, ol.list-no-bullets {
    @include list-no-bullets();
}
