small {
    font-size: 75%;
}

h1, h2, h3, h4, h5, h6 {
    font-style: normal;
    font-weight: normal;
    line-height: 1.618;
    text-rendering: optimizeLegibility;
    -webkit-margin-before: unset;
    -webkit-margin-after: unset;
    -webkit-margin-start: unset;
    -webkit-margin-end: unset;
}

h1 {
    margin-bottom: var(--spacing-xs);
    margin-top: var(--spacing-xxs);
}

h2 {
    margin-bottom: var(--spacing);
    margin-top: 0;
}

h3 {
    margin-bottom: var(--spacing-xs);
    margin-top: 0;
}

h4, h5, h6 {
    margin-bottom: var(--spacing-xxs);
    margin-top: 0;
}

h1, h1 p {
    font-size: $font-size-h1;
    font-family: $font-family-h1;
    line-height: $line-height-h1;
    color: $type-grey-dark;
}

h2, h2 p {
    font-size: $font-size-h2;
    font-family: $font-family-h2;
    line-height: $line-height-h2;
    color: $type-grey-dark;
}

h3, h3 p {
    font-size: $font-size-h3;
    font-family: $font-family-h3;
    line-height: $line-height-h3;
    color: $type-grey-dark;
}

h4, h4 p {
    font-size: $font-size-h4;
    font-family: $font-family-h4;
    line-height: $line-height-h4;
    color: $type-grey-dark;
}

h5, h5 p {
    font-size: $font-size-h5;
    font-family: $font-family-h5;
    line-height: $line-height-h5;
    color: $type-grey-dark;
}

.orange-font {
    color: $accent-orange;
}

p {
    margin-top: 0;
    margin-bottom: var(--spacing);
}

p:last-child {
    margin-bottom: 0;
}

a, form {
    font-family: $font-family-sans-serif;
    font-size: $font-size-t1;
    line-height: $line-height-t1;
}
