$border-radius: 6px 6px 0 6px;

.covid.stoerer {
    background-color: transparent;
    position: absolute;
    right: -86px;
    z-index: 100;
    top: -25px;
    pointer-events: none;
    @include badge($covid-orange-color, $covid-orange-color-light);

    .badge {
        border-radius: $border-radius;
    }

    .badge, .shine {
        transform: rotate(-90deg);
        height: 325px;
        width: 95px;
    }

    .stoerer-text {
        bottom: 205px;
        text-align: left;
        color: #fff;
        //font-family: 'Lato', sans-serif;
        //font-size: $font-size-very-small;
        //line-height: $line-height-t1;
        font-family: $font-family-sans-serif;
        font-size: $font-size-t3;
        line-height: $line-height-t1;
        text-transform:none;
        letter-spacing: 1px;
        left: -104px;

        //img {
        //    margin-top: 6px;
        //    width: $font-size-h2;
        //}
    }

    @media only screen and (max-width: $small-breakpoint) {
        right: -60px;
        top: -53px;
        .badge, .shine {
            transform: rotate(-90deg);
            height: 325px;
            width: 95px;
        }
        .stoerer-text {
            bottom: 205px;
            font-family: $font-family-sans-serif;
            line-height: $line-height-t1;

            //img {
            //    margin-top: 2px;
            //    width: $font-size-h4;
            //}
        }
    }
}

.landingpage .covid.stoerer {
    @include badge($cta-color, $cta-color);
    top: 51px;
    @media only screen and (max-width: $small-breakpoint) {
        top: 56px;
    }

    .badge {
        border-radius: $border-radius;
    }
}
