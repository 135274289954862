.entity-list {
    .letter-container {
        margin: 0 auto;
        width: 100%;
        position: relative;
    }

    @media only screen and (min-width: $medium-breakpoint + 1) {
        .letter-container.columns-list {
            border-bottom: $border;
        }
    }

    @media only screen and (max-width: $medium-breakpoint) {
        margin-right: calc(-1 * var(--side-space));
        margin-left: calc(-1 * var(--side-space));

        div.search-entity {
            border-bottom: $border;
            padding-bottom: 0 !important;

            > a {
                display: inline-block;
                width: 100%;
                height: var(--spacing-l);
                padding-top: 10px;
                padding-left: var(--spacing);

                &[data-name]::after {
                    content: attr(data-name);
                    margin-left: var(--spacing-xxs);
                    display: inline-block;
                    font-size: $font-size-small;
                    color: $type-grey-medium;
                }
            }

            &:first-child {
                border-top: $border;
            }

            a:focus, a:active {
                color: $active-link;
                text-decoration: none;
            }
        }

        &:not(.table) {
            .letter-container:before {
                content: attr(id);
                display: block;
                padding-left: var(--spacing);
                background-color: $mobile-dark-background;
                border-bottom: 1px solid $mobile-border-bottom;
                border-top: 1px solid $mobile-border-top;
                color: #fff;
                font-size: $font-size-h4;
                padding-top: 7px;
                padding-bottom: 5px;
                font-weight: bold;
            }

            .letter-container:first-child {
                padding-top: 0;
                margin-top: var(--spacing);
            }

            a {
                small {
                    color: $type-grey-medium;
                }
            }
        }
    }

    .columns-list:not(.filterable), .entity-list:not(.filtered) .columns-list.filterable {
        > *:nth-child(3n + 2) {
            border-right: $border;
            border-left: $border;
            padding-left: var(--spacing-xs);
        }

        > *:nth-child(-n + 3) {
            padding-top: var(--spacing-xs);
        }

        @media only screen and (max-width: $medium-breakpoint) {
            > *:nth-child(-n + 3) {
                padding-top: 0;
            }

            > *:nth-child(3n + 2) {
                border-right: none;
                border-left: none;
                padding-left: 0;
            }
        }
    }
}

#no-entities-msg.hidden, .nomatch {
    display: none;
}

