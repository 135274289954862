.expander {
    > .title {
        @include flex();
        box-shadow: 0 0 0 1px $grey-light;
        border: $border-white;
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
        cursor: pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 1px;

        div:first-child {
            @include flex();
            width: 100%;
        }

        @media only screen and (max-width: $small-breakpoint) {
            .has-attribute {
                display: none;
            }
        }
        > #interaction-name a{
            color: $type-grey-dark;
        }
    }

    .title:hover, &.expanded .title {
        box-shadow: 0 0 0 1px $grey;
    }

    $arrow-size: 12px;
    .title:before {
        content: "";
        display: inline-block;
        padding-right: $arrow-size;
        height: $arrow-size;
        margin: auto var(--spacing-xs) auto var(--spacing-xs);
        background: transparent url("../../images/userfrontend/arrow_right_blue.svg") no-repeat 0 0;
    }

    &.expanded > .title:before {
        height: 5px;
        background-image: url("../../images/userfrontend/arrow_down_blue.svg");
    }

    > .title-auto {
        @include flex();
        box-shadow: 0 0 0 1px $grey-light;
        border: $border-white;
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
        cursor: pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 1px;

        div:first-child {
            @include flex();
            width: 100%;
        }

        @media only screen and (max-width: $small-breakpoint) {
            .has-attribute {
                display: none;
            }
        }
        > #interaction-name a{
            color: $type-grey-dark;
        }
    }

    .title-auto:hover, &.expanded .title-auto {
        box-shadow: 0 0 0 1px $grey;
    }

    .title-auto:before {
        content: "";
        display: inline-block;
        padding-right: $arrow-size;
        height: $arrow-size;
        margin: auto var(--spacing-xs) auto var(--spacing-xs);
        background: transparent url("../../images/userfrontend/arrow_right_black.svg") no-repeat 0 0;
    }

    &.expanded > .title-auto:before {
        height: 5px;
        background-image: url("../../images/userfrontend/arrow_down_black.svg");
    }

    > .inner {
        border-top: none;
        position: relative;
        display: none;
        background-color: #fff;
        color: $type-grey-medium;

        padding: var(--spacing) var(--spacing-xs) var(--spacing-s) var(--spacing);
        @media screen and (max-width: $medium-breakpoint) {
            padding-left: var(--spacing-s);
        }

        h3 {
            z-index: 0;
        }
    }

    .spinner {
        width: 100%;
        height: 100px;
        background: transparent url("../../images/userfrontend/spinner_dark.svg") no-repeat center center;
        background-size: $spinner-size-big;
    }

    &.freely-accessible > .title {
        box-shadow: inset 0 0 3px 0 $freely-accessible-color;
    }

    &.freely-accessible > .title-auto {
        box-shadow: inset 0 0 3px 0 $freely-accessible-color;
    }
}

.expander.has-error .title {
    border: 1px solid $alert-color;
}

.expander.has-error .title-auto {
    border: 1px solid $alert-color;
}

.pdf-spinner {
    position: fixed;
    top: 0;
    left: 50%;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-image: url("../../images/userfrontend/spinner_dark.svg");
    background-size: $spinner-size-big;
    background-repeat: no-repeat;
    background-position: var(--spacing-xxs) center;
    text-align: center;
}
