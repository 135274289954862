form[name="agent_pharmacology"] {
    div.content[tab-section="pathways"] {
        padding: var(--spacing) 0 0 0;

        > * {
            margin-bottom: 0;
        }

        .explanation {
            margin: 0 0 var(--spacing-xs) var(--spacing-s)
        }
    }

    table.edit-metabolic-effects-table {
        border-left: none;
        border-right: none;
        border-bottom: none;

        textarea, select {
            margin: 0;
        }

        .row-split {
            padding: 0;
            text-align: left;

            > div {
                @include flex();
                padding: var(--spacing-xs);
                flex-direction: row;
                align-items: center;

                div {
                    flex: 1 1 0;
                }
            }

            > div:first-child {
                border-bottom: $border;
            }
        }
    }
}
