.not-granted {
    p {
        margin-bottom: 0;
        display: inline;
    }

    padding: var(--spacing);

    .alerts {
        margin: 0;
    }

    #login-form {
        margin-top: var(--spacing-s);
    }

    #interactions {
        height: 740px;
        background: #fff url("../../images/commonfrontend/interaction_query_blur_1.png") no-repeat 0 var(--spacing);
    }

    #pathways {
        height: 673px;
        background: #fff url("../../images/commonfrontend/interaction_query_blur_2.png") no-repeat 0 var(--spacing);
    }

    #pharmacodynamic {
        height: 750px;
        background: #fff url("../../images/commonfrontend/interaction_query_blur_3.png") no-repeat 0 var(--spacing);
    }

    #doubleprescriptions {
        height: 500px;
        background: #fff url("../../images/commonfrontend/interaction_query_blur_4.png") no-repeat 0 var(--spacing);
    }

    #tdmcheck {
        height: 614px;
        background: #fff url("../../images/commonfrontend/interaction_query_blur_5.png") no-repeat 0 var(--spacing);
    }
}

.tab-box .content.active.content-blurred {
    height: 750px;

    .not-granted {
        position: absolute;
        top: -490px;
        left: 0;
        right: 0;
        margin: calc(var(--spacing) * 4);
        background-color: #fff;
        padding: var(--spacing-l);
        border: $border-dark;
        z-index: 9999;

        @media screen and (max-width: $medium-breakpoint) {
            margin: var(--spacing);
            top: -530px;
        }
    }

    &[tab-section="interactions"] {
        background: #fff url("../../images/commonfrontend/interaction_query_blur_1.png") no-repeat 0 var(--spacing);
    }

    &[tab-section="pathways"] {
        background: #fff url("../../images/commonfrontend/interaction_query_blur_2.png") no-repeat 0 var(--spacing);
    }

    &[tab-section="pharmacodynamic"] {
        background: #fff url("../../images/commonfrontend/interaction_query_blur_3.png") no-repeat 0 var(--spacing);
    }

    &[tab-section="doubleprescriptions"] {
        background: #fff url("../../images/commonfrontend/interaction_query_blur_4.png") no-repeat 0 var(--spacing);
    }
}

