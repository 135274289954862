$verdictButtonSize: 24px;
.interaction-verdict-edit {
    padding: var(--spacing-l) var(--spacing-l) var(--spacing) var(--spacing-l);
    border: $border-dark;

    .references-container .reference-display-text {
        max-width: unset;
    }

    .verdict-count {
        color: $type-grey-dark;
        font-size: $font-size-large;
        line-height: $font-size-large;
        opacity: 0.1;
        position: absolute;
        right: var(--spacing);
        top: 1px;

        &::before {
            content: "#";
            font-size: 40px;
        }
    }

    button.remove-interaction-verdict {
        position: absolute;
        right: -1px;
        bottom: -1px;

        &::before {
            background: transparent url("../../images/editorfrontend/pen_minus.svg") no-repeat center center;
            height: $button-size-small;
            width: $button-size-small;
            content: "";
            display: inline-block;
            margin: 8px 0 0 8px;
        }

        cursor: pointer;
        border: $border-dark;
        z-index: 100;
        margin: 0;
        padding: 0;
        $width: 60px;
        $height: $width;
        width: $width;
        height: $width;
        border-radius: $width 0 0 0;
    }
}

#add-interaction-verdict {
    padding: 3px 9px 2px 9px;

    span {
        vertical-align: 6px;
    }

    &::before {
        content: "";
        display: inline-block;
        height: $button-size-small;
        width: $button-size-small;
        margin-right: var(--spacing-xs);
        background: transparent url("../../images/editorfrontend/pen_plus.svg") no-repeat center center;
    }
}
