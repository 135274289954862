ul.main-menu, ul.sub-menu {
    padding-left: calc(var(--side-space) - var(--spacing-xs));
    padding-right: calc(var(--side-space) - var(--spacing-xs));
}

ul.main-menu {
    margin: 0;
    list-style: none;
    height: $nav-height;
    border-top: $menu-border;
    border-bottom: $menu-border;
    box-shadow: $menu-box-shadow-white;

    a:active, a:hover, &:active a {
        text-decoration: none;
    }

    li {
        height: $nav-height - 1;
        display: inline-block;

        &:hover {
            border-right: $menu-border;
            border-left: $menu-border;
            margin: 0 -1px 0 -1px;
            background-color: $menu-item-hover-bg-color;

            a {
                text-decoration: none;
                color: $menu-item-hover-font-color;
            }
        }

        &.current {
            border-right: $menu-border;
            border-left: $menu-border;
            margin: 0 -1px 0 -1px;
            background-color: #fff;

            a {
                color: $springer-logo-blue;
            }
        }

        &.highlight {
            > a {
                color: $highlight-color;
            }

            &:hover > a {
                color: $highlight-color-light;
            }
        }
    }

    a {
        font-size: $font-size-t1;
        padding: 10px 10px 0 var(--spacing-xs);
        color: $menu-font-color;
        display: block;
        height: $nav-height;
    }
}

ul.main-menu li.has-main-sub-nav {

    > span {
        cursor: pointer;
        font-size: $font-size-t1;
        padding: 10px 23px 0 var(--spacing-xs);
        color: $menu-font-color;
        display: block;
        height: $nav-height;
        background: transparent url("../../images/userfrontend/arrow_down_grey.svg") no-repeat right var(--spacing-xs) center;
    }

    &:hover {
        background-color: $menu-item-hover-bg-color;
        span {
            color: $action-blue;
            text-decoration: none;
            background: transparent url("../../images/userfrontend/arrow_down_blue.svg") no-repeat right var(--spacing-xs) center;
        }

        ul.menu_level_1 {
            display: block;
        }
    }

    ul.menu_level_1 {
        @include list-no-bullets();

        columns: 1;
        column-gap: 0;
        display: none;
        z-index: 1000000;
        position: absolute;
        background-color: $sub-menu-bg-color;
        border-right: $border;
        border-left: $border;
        border-bottom: $border;
        margin-left: -1px;
        margin-top: -1px;
        box-shadow: $box-shadow;
        width: $main-sub-menu-width;

        li {
            width: 100%;
            display: table;
            height: 30px;

            a {
                color: $type-grey-medium;
                padding: var(--spacing-xxs) var(--spacing-xs);
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                height: 100%;
            }

            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            &:hover {
                background-color: $action-blue-light-light;
                border: 1px solid $action-blue-light-light;
                a {
                    color: $action-blue;
                    text-decoration: none;
                }
            }

            &.current {
                border-right: $border-white;
                border-left: $border;
                margin: 0 -1px 0 -1px;
                background-color: $sub-menu-bg-color;

                a {
                    color: $springer-logo-blue;
                }
            }
        }
    }
}


ul.sub-menu {
    margin: 0;
    list-style: none;

    background-color: $sub-menu-bg-color;
    height: $nav-height;
    border-bottom: $menu-border;

    a:active, a:hover, &:active a {
        text-decoration: none;
    }

    > li {
        height: $nav-height;
        display: inline-block;

        > span, > a {
            font-size: $font-size-t1;
            line-height: $line-height-t1;
            padding: 10px 0 0 9px;
            display: inline-block;
            color: $menu-font-color;
            height: $nav-height;
        }

        &:hover {
            border-right: $menu-border;
            border-left: $menu-border;
            margin: 0 -1px 0 -1px;

            > a {
                color: $menu-item-hover-font-color;
                text-decoration: none;
            }

            .menu_level_1 {
                display: block;
            }
        }

        &.has-sub-nav {
            background: transparent url("../../images/userfrontend/arrow_down_grey.svg") no-repeat right var(--spacing-xs) center;

            > a, > span {
                padding-right: var(--spacing);
            }

            > span {
                cursor: pointer;
            }

            &:hover {
                background: transparent url("../../images/userfrontend/arrow_down_blue.svg") no-repeat right var(--spacing-xs) center;

                > a, > span {
                    height: $nav-height;
                    border-bottom: $menu-border-white;
                }
            }
        }

        &:not(.has-sub-nav) {
            > a, > span {
                padding-right: 10px;
            }
        }
    }

    .menu_level_1 {
        @include list-no-bullets();

        columns: 2;
        column-gap: 0;
        display: none;
        z-index: 1000000;
        position: absolute;
        background-color: #fff;
        border-right: $border;
        border-left: $border;
        border-bottom: $border;
        margin-left: -1px;
        box-shadow: $box-shadow;
        width: $sub-menu-width;

        li {
            width: 100%;
            display: table;
            height: 30px;

            a {
                color: $type-grey-medium;
                padding: var(--spacing-xxs) var(--spacing-xs);
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                height: 100%;
            }

            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            &:hover {
                background-color: $action-blue-light-light;

                a {
                    color: $action-blue;
                    text-decoration: none;
                }
            }
        }
    }
}



