.columns-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--spacing-xs);

    > * {
        padding-bottom: var(--spacing-xs);
    }

    @media only screen and (max-width: $medium-breakpoint) {
        display: block;
    }
}
