$cta-color: lighten($springer-logo-blue, 10%);
$covid-color: #AB0130;
$covid-color-light: lighten($covid-color, 10%);
$covid-orange-color: $orange;
$covid-orange-color-light: lighten($covid-orange-color, 10%);
$font-size-covid-headline: 46px;
$font-size-covid-headline-medium: 34px;
$font-size-covid-headline-small: 28px;

$info-font-color-dark: #486a7e;
$info-font-color-light: #0070a8;

$info-font-size-large: 16px;
$info-font-size-small: 14px;

$covid-key-visual-bg-color: #000;
$covid-key-visual-bg-transparency: 0.56;
$covid-key-visual-bg-transparency-light: 0.25;
$covid-key-visual-bg-transparency-dark: 0.7;

$freely-accessible-color: $covid-color;
$freely-accessible-attribute-text: 'COVID19';

$highlight-color: $covid-color;
$highlight-color-light: $covid-color-light;
