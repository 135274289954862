.benkert-book-text {
    text-align: center !important;
    position: absolute;
    left: 57px;
    top: -8px;
}

.benkert-book-text-device {
    text-align: center !important;
    padding-left: 60px;
}

.announcement-item {
    padding-top: var(--spacing-xs);
}

/* Teaser Cards Container */
.teaser-flex-container {
    display:flex;
    flex-direction: row;
    gap: 1rem;
}

.teaser-flex-container > * {
    flex-basis : 100%;
}

.teaser-flexbox-item {
    border-radius: 0 0 0 1.25rem;
    padding : 10px 10px;
    color: #FFFFFF;
    text-align: left;

    a {
        color: #FFFFFF;
        text-decoration: none;
        font-size: $font-size-t1;
        font-family: $font-family-serif;
        line-height: $line-height-t1;

        &:hover {
            text-decoration: underline;
        }

        &:focus, &:active {
            color: #FFFFFF;
            text-decoration: underline;
        }
    }

    h3 {
        color: #FFFFFF;
    }
}

.teaser-flexbox-item-1 {
    min-height: $teaser-flexbox-item-min-height;
    background-color: $teaser-flexbox-color-1;
    margin-left: var(--spacing);
}

.teaser-flexbox-item-2 {
    min-height: $teaser-flexbox-item-min-height;
    background-color: $teaser-flexbox-color-2;
}

.teaser-flexbox-item-3 {
    min-height: $teaser-flexbox-item-min-height;
    background-color:  $teaser-flexbox-color-3;
    margin-right: var(--spacing);
}

.flexbox-item-card {
    border: $border;
    border-top: 5px solid $accent-orange;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-collapse: collapse;
    box-shadow: $box-shadow;
    padding : 10px 10px;
    font-family: $font-family-serif;
    font-size: $font-size-t2;
    line-height: $line-height-t2;
    color: $type-grey-dark;
    text-align: left;

    a {
        cursor: pointer;
    }

    .read-more {
        text-align: left;
        cursor: pointer;

        a:hover {
            text-decoration: underline;
        }

        a::before {
            content: '\00BB\0020';

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .flexbox-item-card-announcement-list {
        ul {
            @include list-no-bullets();
            min-height: 230px;
            margin-bottom: var(--spacing-xxs);

            li {
                padding: var(--spacing-xxs) 0;
                border-bottom: $border;

                &:before {
                    //content: "»";
                    display: inline-block;
                    color: $action-blue;
                    width: 7%;
                    vertical-align: top;
                }

                a {
                    display: inline-block;
                    width: 93%;
                }
            }

            li:first-child {
                border-top: $border;
            }

            li:last-child {
                border-bottom: none;
            }
        }
    }

    .flexbox-item-card-list {
        ul {
            @include list-no-bullets();
            min-height: 230px;
            margin-bottom: var(--spacing-xxs);

            li {
                padding: calc(var(--spacing-xxs) + 1px) 0;
                border-bottom: $border;

                &:before {
                    content: "»";
                    display: inline-block;
                    color: $action-blue;
                    width: 5%;
                    vertical-align: top;
                }

                a {
                    display: inline-block;
                    width: 93%;
                }

                span {
                    display: inline-block;
                    color: $type-grey-light;
                    padding-left: 7%;
                    vertical-align: top;
                }
            }

            li:first-child {
                border-top: $border;
            }

            li:last-child {
                border-bottom: none;
            }
        }
    }
}

.flexbox-item-card-1 {
    min-height: $teaser-flexbox-item-min-height;
    margin-left: var(--spacing);
}

.flexbox-item-card-2 {
    min-height: $teaser-flexbox-item-min-height;
    margin-left: var(--spacing);
}

.flexbox-item-card-3 {
    min-height: $teaser-flexbox-item-min-height;
    margin-right: var(--spacing);
}

.flexbox-item-card-bottom {
    position: absolute;
    bottom: 0;
    width:96%;
    border-top: $border;
    padding-top: 5px;
    padding-bottom: 5px;
}

.flexbox-item-card-link {
    $size: 15px;
    width: $size;
    height: $size;
    background: transparent url("../../images/userfrontend/external_link.svg") no-repeat 0 0;
    background-size: $size;
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;

    &:hover {
        opacity: 0.5;
    }
}

.most-recently-item-list {
    ul {
        @include list-no-bullets();
        min-height: 230px;
        margin-bottom: var(--spacing-xxs);

        li {
            padding: calc(var(--spacing-xxs) + 1px) 0;
            border-bottom: $border;

            &:before {
                content: "»";
                display: inline-block;
                color: $action-blue;
                width: 5%;
                vertical-align: top;
            }

            a {
                display: inline-block;
                width: 95%;
            }

            span {
                display: inline-block;
                color: $type-grey-light;
                padding-left: 5%;
                vertical-align: top;
            }
        }

        li:first-child {
            border-top: $border;
        }

        li:last-child {
            border-bottom: none;
        }
    }
}

@media only screen and (max-width: $small-breakpoint) {
    .teaser-flex-container {
        flex-direction: column;
    }

    .teaser-flexbox-item-1,.teaser-flexbox-item-2,.teaser-flexbox-item-3 {
        margin-left: calc(var(--spacing) - 9px);
        margin-right: calc(var(--spacing) - 9px);
    }

    .flexbox-item-card-1, .flexbox-item-card-2,.flexbox-item-card-3  {
        margin-left: calc(var(--spacing) - 9px);
        margin-right: calc(var(--spacing) - 9px);
    }

    .seperator-arrow {
        .down-arrow {
            left: 43.5%;
        }
    }
}
