#pathways {
    li {
        margin-top: var(--spacing-xxs);
        padding: var(--spacing-s);
        display: block;
        border: $border;
        cursor: pointer;
    }

    li:hover {
        background-color: $grey-dark;
    }

    .sort-handle {
        background: transparent url("../../images/editorfrontend/swap_vertical.svg") no-repeat center center;
        width: 16px;
        height: 16px;
        background-size: 12px;
        display: inline-block;
        vertical-align: text-bottom;
    }
}
