ul.pagination {
    @include list-no-bullets();

    display: block;

    li {
        display: block;
        float: left;
    }

    li.current a, li.current button {
        background: $action-blue;
        color: #fff;
        cursor: default;
    }

    li a, li button {
        border-radius: 3px;
        transition: background-color 300ms ease-out;
        display: block;
        padding: var(--spacing-xxs) var(--spacing-s);
    }

    li:hover a, li a:focus, li:hover button, li button:focus {
        background: #e6e6e6;
    }
}
