.landingpage {
    .covid-key-visual-landingpage {
        background: url("../../images/covid/key_visual_landingpage.jpg") center center no-repeat;
    }

    .claim {
        top: 130px;
        padding: var(--spacing-l) !important;
        z-index: 100;

        @media only screen and (max-width: $small-breakpoint) {
            margin-bottom: var(--spacing-l);
            top: 0;
        }
    }

    .title-container-outer {
        .title-container-animation {
            background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency-light);
            left: -66px;
        }
    }

    .cta-description a {
        font-size: $font-size-covid-headline-small;
    }

    .claim {
        span:first-child {
            font-size: $font-size-covid-headline;
        }

        span:last-child {
            font-size: $font-size-h3;
            font-weight: bold;
        }

        @media only screen and (max-width: $small-breakpoint) {
            span:first-child {
                font-size: $font-size-covid-headline-small;
            }

            span:last-child {
                font-size: $font-size-h4;
            }
        }

        @media only screen and (min-width: $small-breakpoint + 1) and (max-width: $medium-breakpoint) {
            span:first-child {
                font-size: $font-size-covid-headline-medium;
            }
        }

        @media only screen and (min-width: $small-breakpoint + 1) {
            padding: 39px var(--spacing) var(--spacing) 232px;
        }
    }
}
