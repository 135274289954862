img {
    max-width: 100%;
}

.carousel-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;

    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 9px;
        color: $divider-white;
        font-weight: bold;
        font-size: 20px;
        transition: all 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        z-index: 9999;
    }

    .prev {
        left: -5px !important;
        text-decoration: none !important;
    }

    .next {
        right: -5px !important;
        text-decoration: none !important;
    }

    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
        color: $divider-white;
        text-decoration: none !important;
    }
}

.carousel-menu {
    position: absolute;
    left: 0;
    z-index: 900;
    width: 100%;
    bottom: 0;
}

.carousel-menu label {
    cursor: pointer;
    display: inline-block;
    width: 5px;
    height: 5px;
    background: $horizontal-dots-background-hover;
    border-radius: 50px;
    margin: 0 .2em 1em;

    &:hover {
        background: $horizontal-dots-black-light;
    }

    &:focus, &:active {
        background: $horizontal-dots-black-light;
    }
}

.carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 10;
    padding: 8em 1em 0;
    background-size: cover;
    background-position: 50% 50%;
    transition: left 0s .85s;
}

[id^="carousel"]:checked + .carousel {
    left: 0;
    z-index: 100;
    transition: left .85s ease-out;
}

.carousel-1 {
    background-image: url("../../images/springer/key_visual_psiac.png");
}
.carousel-2 {
    background-image: url("../../images/springer/key_visual_medicine.jpg");
}
.carousel-3 {
    background-image: url("../../images/springer/key_visual_person.jpg");
}
