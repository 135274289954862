.info-box {
    padding: 10px;

    h3 {
        color: $action-blue;
    }

    &.white {
        border: $border;
    }

    &.grey {
        box-shadow: 0 0 0 1px $grey;
        border: $border-white;
        background-color: $gradient-grey-light-light;
        background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);
    }

    &.yellow {
        box-shadow: 0 0 0 1px $yellow;
        border: $border-white;
        background-color: $yellow-light;
    }

    &.primary {
        box-shadow: 0 0 0 1px $action-blue;
        border: $border-white;
        background-color: $gradient-blue-light-light;
        background-image: linear-gradient(to bottom, $gradient-blue-light-light, $gradient-blue-light-dark);
    }
}

.tabs-content .info-box {
    border: none;
}
