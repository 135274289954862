.landingpage {
    text-align: center;
    font-family: $font-family-headline;
    background-color: $grey-light;

    header {
        margin-bottom: 0 !important;
    }

    .key-visual-landingpage {
        background: url("../../images/springer/key_visual_landingpage.png") center center no-repeat;
        width: 100%;
        height: 550px;
        border-top: $border;

        &:hover .key-visual-overlay {
            transform: scale(1.1) skewX(-18deg);
        }

        @media only screen and (max-width: $small-breakpoint) {
            height: 100%;
        }
    }

    .title {
        font-family: $font-family-headline;
        color: #fff;
        font-size: $font-size-very-large;
        line-height: $font-size-very-large;
        text-decoration: none;
        text-align: left;
        display: block;
        padding: 119px var(--spacing);

        @media only screen and (max-width: $small-breakpoint) {
            padding: var(--spacing);
            position: absolute;
            font-size: $font-size-large;
            top: 0;
            color: $springer-logo-blue;
        }
    }


    .title-container-outer {
        overflow: hidden;
        height: 100%;
        width: 249px;
        position: absolute;
        top: 0;

        @keyframes slideInFromLeft {
            0% {
                transform: skewX(0) translateX(-100%);
            }
            100% {
                transform: skewX(-23deg) translateX(0);
            }
        }

        .title-container-animation {
            transform: skewX(-23deg);
            transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
            transition-duration: 2s;
            background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency-light);
            height: 100%;
            left: -75px;
            position: absolute;
            transition: all .1s ease-in-out;
        }

        .title-container-animation:first-of-type {
            animation: slideInFromLeft 1.5s ease-out 0s 1;
            width: 200px;
        }

        .title-container-animation:nth-of-type(2) {
            animation: slideInFromLeft 1s ease-out 0s 1;
            width: 240px;
        }

        @media only screen and (max-width: $small-breakpoint) {
            .title-container-animation {
                display: none;
            }
        }
    }

    .claim {
        background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency);
        width: 100%;
        right: 0;
        top: 75px;
        padding: var(--spacing-l) var(--spacing) var(--spacing-l) 232px;
        position: absolute;

        @media only screen and (max-width: $small-breakpoint) {
            background-color: rgba($key-visual-bg-color, $key-visual-bg-transparency-dark);
            padding: var(--spacing);
            z-index: 100;
            position: relative;
            top: 0;
            margin-bottom: var(--spacing-l);
            margin-top: 120px;
        }
    }

    .cta-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        .button {
            font-size: $font-size-h1;
            font-family: $font-family-headline;
            text-decoration: none;
            text-transform: uppercase;
            padding: var(--spacing) var(--spacing-xl);
            background: none $cta-color;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 45px 70px 0 70px;
                border-color: $cta-color transparent transparent transparent;
                position: absolute;
                bottom: -25px;
                right: 0;
                left: 0;
                margin-left: auto;
                margin-right: auto;
                z-index: 100;
            }

            &:hover, &:focus, &:active {
                color: #fff;
                background-color: darken($cta-color, 10%);

                &:after {
                    border-color: darken($cta-color, 10%) transparent transparent transparent;
                }
            }
        }

        @media only screen and (max-width: $small-breakpoint) {
            position: relative;
        }
    }

    .cta-description {
        padding: var(--spacing-xl) var(--spacing) var(--spacing-l);

        a {
            font-family: $font-family-headline;
            color: $type-grey-medium;
            font-size: 40px;
            line-height: 44px;
            padding: var(--spacing-s);

            @media only screen and (min-width: $small-breakpoint + 1) {
                &:hover, &:focus {
                    text-decoration: none;
                    border: 2px dotted $cta-color;
                }
            }
        }
    }

    .cta-sub-description {
        color: $type-grey-dark;
        font-size: $font-size-h3;
        padding: var(--spacing-xl);
        background-color: $grey-medium;

        a {
            font-size: $font-size-h3;
            font-family: $font-family-headline;
            text-decoration: none;
            color: $cta-color;

            &:hover {
                color: darken($cta-color, 10%);
            }
        }
    }

    h1 {
        color: #fff;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-family: $font-family-headline;
    }

    footer {
        margin-top: 0;
        @media only screen and (max-width: $medium-breakpoint) {
            .footer-copyright {
                margin-top: 0 !important;
            }
        }
    }
}
