.page-wrapper[data-version] {
    outline: 1px solid $orange;

    &:after {
        content: attr(data-version);
        display: block;
        position: absolute;
        text-align: center;
        background-color: transparent;
        border: 1px dashed $orange;
        color: $orange;
        font-size: $font-size-h4;
        top: 25px;
        left: 25%;
        transform: rotate(15deg);
        z-index: 1000;
        padding: var(--spacing-xs) var(--spacing-s);
        box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.25);
    }
}
