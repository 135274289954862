.metadata {
    .items div, .items li, .attribute {
        padding-bottom: var(--spacing-xxs);
        margin-bottom: var(--spacing-xxs);
    }

    .attribute {
        font-weight: bold;
        border-bottom: $border;
    }

    .items {
        &div:not(first-child):not(last-child), ul li:not(first-child):not(last-child) {
            border-bottom: $border;
        }

        ul {
            @include list-no-bullets();

            li:last-child {
                border: none
            }
        }
    }

    .metadata-interaction-comment
    {
        max-width:270px;
        word-wrap:break-word;
        text-align: justify;
    }

    .metadata-message
    {
        padding: 15px 5px 15px 5px;
    }

    .anchor-link {
        text-decoration: none;
        padding-top: 2px;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        text-align: center;
        line-height: normal;
        display: inline-block;
        font-size: $font-size-t2;
        outline: none;
        height: $line-height-h5;
        margin-top: 3px;
        background: transparent;
        border: 1px solid $blue-dark;
        color: $blue-dark;
    }

    .anchor-link:hover {
        text-decoration: none;
        background: $action-blue;
        color: #fff;
    }

    .anchor-link:active {
        text-decoration: none;
    }

    div.morelink {
        text-decoration:none;
        outline: none;
    }

    .morecontent span:not(:last-child) {
        display: none;
    }
}
