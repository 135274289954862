.interaction-rating-legend {
    $iconSize: 14px;
    display: inline-block;
    vertical-align: 2px;
    position: initial;
    margin-left: var(--spacing-xxs);

    > .interaction-rating-legend-button {
        background: transparent url("../../images/userfrontend/info-circle.svg") no-repeat center center;
        background-size: $iconSize;
        width: $iconSize;
        height: $iconSize;
        cursor: pointer;

        &:hover {
            background-image: url("../../images/userfrontend/info-circle-orange.svg");

            & + .interaction-rating-content {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .interaction-rating-content {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s linear;

        $width: $max-width * (3/4);
        z-index: 9999;
        position: absolute;
        width: $width;

        left: calc(var(--side-space) * -1 + #{$max-width} / 2 - #{$width} / 2);
        @media screen and (max-width: $medium-breakpoint) {
            width: 85vw;
            left: 0;
        }

        border: 1px solid $mobile-border-top;
        box-shadow: 9px 9px 8px 1px rgba(0, 0, 0, 0.5);

        background: transparent url("../../images/userfrontend/info-circle-orange.svg") no-repeat top var(--spacing-s) right var(--spacing-s);
        background-size: 25px;
        margin: var(--spacing-s) 0 var(--spacing) 0;

        padding: var(--spacing-l) var(--spacing) var(--spacing-l) var(--spacing);
        @media screen and (max-width: $medium-breakpoint) {
            padding: var(--spacing-l) var(--spacing);
        }

        background-color: $blue-light;
        color: #000;
        font-size: $font-size-t1;
        font-family: $font-family-sans-serif;
        font-weight: normal;

        > div {
            padding-left: var(--spacing-s);

            .interaction-rating-severity-level {
                position: absolute;
                font-weight: bold;
                left: 0;
            }
        }
    }
}

label .interaction-rating-legend {
    margin-left: var(--spacing-xxs);
}
