.page-wrapper.tdmtool {
    .info {
        margin-top: var(--spacing);
    }

    footer {
        margin-top: 0;
    }

    header {
        margin-bottom: 0;
    }

    #spinner {
        background-image: url("../../images/userfrontend/spinner_dark.svg");
        background-size: $spinner-size;
        background-repeat: no-repeat;
        background-position: var(--spacing-xxs) center;
        padding-left: var(--spacing);
        width: 180px;
        text-align: center;
        height: var(--spacing);
        margin: var(--spacing-l) auto;
    }

    #tdm-tool-iframe {
        border: none;
        overflow: hidden;
        display: none;
    }

    #tdm-tool-not-available {
        display: none;
    }
}

.tdm-info-highlight {
    color: $orange;
    font-weight: bold;
}
