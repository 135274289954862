input.interaction_query_acting_entity {
    margin-top: 0;

    & + span.error {
        margin-top: var(--spacing-xxs);
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: var(--spacing-l);
}

#additional-interactions-container {
    display: none;

}
h3 > button {
        background-color: transparent !important;
        background-image: none !important;
        text-align: left;
        transition: none;
        padding: 0;
        font-size: 100%;
        font-family: inherit;
        color: $action-blue;

        &:hover {
            text-decoration: underline;
        }
    }

.medication-search-count {
    margin-top: 10px;
    h5 {
        padding-left: 15px;
    }
    > .rating-severity {
        box-shadow: $box-shadow;
        border: 1px solid $grey;;
        background-color: #F9F9F9;
        padding-top: 10px;
        padding-bottom: 10px;
        > .row {
            padding-left: 35px;
            margin-bottom: 0px;

            > .interaction-rating {
                display: inline-block;
                width: 100%;

                span {
                    padding-left: $search-padding-left;
                }
            }
        }
    }
}

.square {
    height: $square-size;
    padding-top: $square-size;
    width: $square-size;
    font-size: $search-font-size;
    position: absolute;
    margin-top: 4px;
}

.rating-severity-color1 {
    background-color: $rating-severity-color1;
}

.rating-severity-color2 {
    background-color: $rating-severity-color2;
}

.rating-severity-color3 {
    background-color: $rating-severity-color3;
}

.rating-severity-color4 {
    background-color: $rating-severity-color4;
}

.rating-severity-color5 {
    background-color: $rating-severity-color5;
}

.rating-severity-color6 {
    background-color: $rating-severity-color6;
}

.rating-severity-color7 {
    background-color: $rating-severity-color7;
}

.rating-severity-color8 {
    background-color: $rating-severity-color8;
}

.rating-span {
    padding-left: $search-padding-left;
}
