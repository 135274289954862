.top-action-button-container {
    @include flex();

    .flex-order-first-top {
        order: -1;
    }

    .flex-order-last-top {
        order: 99;
    }

    @media only screen and (min-width: $small-breakpoint + 1) {
        margin-left: calc(-1 * var(--spacing-xs));

        > * {
            margin: 0 0 0 var(--spacing-xs);
        }

        align-items: baseline;

        .flex-order-last-top {
            margin-left: auto;
        }
    }

    @media only screen and (max-width: $small-breakpoint) {
        flex-direction: column-reverse;

        > *:not(.flex-order-last-top):not(.flex-order-first-top) {
            margin-top: var(--spacing-xs);
        }

        .flex-order-first-top {
            margin-top: var(--spacing-s);
        }
    }
}
