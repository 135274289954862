form[name="interaction"] {
    fieldset {
        border: none;
    }

    legend, fieldset {
        margin: 0;
        padding: 0;
    }

    legend {
        margin: 0 0 var(--spacing-s) 0;
        width: 100%;
    }

    fieldset:not(:first-child) legend {
        padding: var(--spacing) 0 0 0;
    }

    legend label, .agent-selection label {
        font-family: $font-family-h3;
        font-size: $font-size-h3;
        color: $type-grey-dark;
        font-weight: normal;
    }

    .agent-selection label {
        padding: 0 0 var(--spacing-xs) 0;
    }

    legend + div {
        margin-bottom: var(--spacing);
    }

    .agent-selection {
        margin-bottom: var(--spacing-l);
    }

    $width: 100%;
    $height: 60px;
    $iconSize: 32px;
    $spaceLeft: 80px;

    .edit-not-relevant-to-content-radio-group > label, .ready-to-publish-checkbox {
        vertical-align: top;
        width: $width;
        height: $height;
        position: relative;

        span {
            background-repeat: no-repeat;
            background-position: var(--spacing-s) center;
            background-size: $iconSize;
            padding-left: $spaceLeft;
            width: $width;
            height: $height;
            line-height: $height;
            display: block;
            border: $border-dark;
            position: absolute;
            top: 0;
        }

        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            margin: 0 0 0 calc(#{$spaceLeft} - var(--spacing-s) - 5px);
        }
    }

    .edit-not-relevant-to-content-radio-group > label {
        // major edit
        &:first-child {
            margin-bottom: var(--spacing-s);

            span {
                border: 1px solid $info-color;
                background-color: $blue-light;
                background-image: url("../../images/interactionauthor/pen2.svg");
            }

            &:hover span {
                border: 1px solid $blue-dark;
                background-color: $info-color;
            }
        }

        // minor edit
        &:nth-child(2) {
            span {
                border: 1px solid $warning-color;
                background-color: $yellow-light;
                background-image: url("../../images/interactionauthor/spell_check.svg");
            }

            &:hover span {
                background-color: $warning-color;
                border: 1px solid $yellow-dark;
            }
        }
    }

    .ready-to-publish-checkbox {
        font-weight: normal;
        cursor: pointer;

        span {
            border: 1px solid $yellow;
            background-color: $yellow-light;
            background-image: url("../../images/interactionauthor/book_bookmarked.svg");
        }

        &:hover input[type="checkbox"]:not(:checked) + span {
            background-color: $warning-color;
            border: 1px solid $yellow-dark;
        }


        &:hover input[type="checkbox"]:checked + span {
            border: 1px solid $green-dark;
            background-color: $green-medium;
        }

        input[type="checkbox"]:checked + span {
            border: 1px solid $green;
            background-color: $green-light;
            background-image: url("../../images/interactionauthor/book.svg");
        }
    }
}
