footer {
    font-family: $font-family-sans-serif;
    font-size: $font-size-t2;
    line-height: $line-height-t2;

    a {
        color: $menu-font-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $menu-font-color;
        }

        &:focus, &:active {
            color: $type-grey-light;
            text-decoration: underline;
        }
    }

    background-image: $header-footer-bg-image;
    box-shadow: 0 -1px 0 0 $grey;
    color: $menu-font-color;

    @media only screen and (min-width: $medium-breakpoint + 1) {
        border-top: 1px solid $divider-white;
    }

    .footer-marketing {
        @media only screen and (min-width: $medium-breakpoint + 1) {
            @include badge;
            color: #fff;
            right: -7px;
            top: var(--spacing);
            width: 90px;
            height: 40px;
            position: absolute;
            z-index: 999;

            .badge {

                a {
                    font-family: $font-family-h4;
                    font-size: $font-size-h4;
                    line-height: $line-height-h4;
                    color: #fff;
                    padding: 6px 5px 6px 10px;
                    display: inline-block;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        @media only screen and (max-width: $medium-breakpoint) {
            color: #fff;
            padding: var(--spacing-xs);
            text-align: center;
            background-color: $orange;
            background: linear-gradient(to bottom, $orange 0%, $accent-orange 100%);
            box-shadow: -2px 2px 6px -1px rgba(0, 0, 0, 0.45);

            a {
                font-family: $font-family-h4;
                font-size: $font-size-h4;
                line-height: $line-height-h4;
                color: #fff;
                display: inline-block;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .footer-nav {
        padding-top: var(--spacing);

        ul {
            @include list-no-bullets();
        }

        &:not(:last-child) .left-nav {
            box-shadow: $menu-box-shadow;
            border-right: $menu-border-white;
        }
    }

    .footer-copyright {
        text-align: left;
        background-image: $springer-nature-logo-footer;
        background-repeat: no-repeat;
        padding: var(--spacing-xl) var(--side-space) var(--spacing-l) var(--side-space);
        background-position: var(--side-space) var(--spacing-l);

        @media only screen and (min-width: $medium-breakpoint + 1) {
            padding-top: var(--spacing-l);
            background-position: var(--side-space) calc(var(--spacing-l) - var(--spacing));

            &:before {
                content: "";
                background: url("../../images/springer/watermark.png") no-repeat center center;
                width: 71px;
                height: 86px;
                position: absolute;
                display: block;
                right: var(--spacing);
                bottom: calc(var(--spacing) + 1px);
            }
        }
    }
}
.scroll-to-top {
    position: fixed;
    display: none;
    bottom: 70px;
    right: $scroll-icon-size;
    z-index: 99;
    border-radius: $border-radius;
    height: $scroll-icon-size;
    width: $scroll-icon-size;
    cursor: pointer;
    background-image: url("../../images/userfrontend/up-arrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 3rem;
}

@media only screen and (max-width: $medium-breakpoint) {
    footer .burger-menu {
        margin: var(--spacing-s) 0;
    }
}
