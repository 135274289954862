.cyp-description p {
    clear: both;
}

.cyp-description dt {
    float: left;
    clear: left;
}

.cyp-description dl dd, .expander > .inner .cyp-description dl dd {
    font-size: $font-size-t3;
}

.cyp-description {
    background-color: $grey-light;
    padding: var(--spacing-xs);
    font-size: $font-size-t3;
    line-height: $line-height-t3;
    margin-bottom: var(--spacing-xs);
}

dl.cyp-legend {
    height: 15px;
}

dl.cyp-legend dt {
    display: inline;
    margin: 0;
}

dl.cyp-legend dd {
    display: inline;
    margin-left: var(--spacing-xs);
}

dl.cyp-legend dt:after {
    content: " ";
}

dl.cyp-legend dd:after {
    content: ", ";
}

dl.cyp-legend dd:last-child:after {
    content: "";
}

@media screen and (max-width: $medium-breakpoint) {
    dl.cyp-legend {
        height: 90px;
    }
}

.cyp-additional-informations {
    table {
        display: table;
        border: none;
        table-layout: auto;

        td {
            padding: 0;
            text-align: left;
            border: none;
            font-size: $font-size-t3;
            line-height: $line-height-t3;
            vertical-align: top;

            img {
                vertical-align: -3px;
            }
        }

        tr {
            margin-bottom: var(--spacing-xs);

            td:first-child {
                padding-right: var(--spacing-xs);
            }
        }

        > * {
            display: table-cell;
        }
    }

    p, a {
        font-size: $font-size-t3;
        line-height: $line-height-t3;
    }
}
