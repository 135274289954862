.clear-input {
    height: $cross-size;
    width: $cross-size;
    background-size: $cross-size;
    background: transparent url("../../images/userfrontend/cross_grey.svg") no-repeat center center;
    cursor: pointer;
    position: absolute;
    right: var(--spacing-s);
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    &.remove-domain-error {
        bottom: 50px !important;
    }
    &:hover {
        background-image: url("../../images/userfrontend/cross_grey_dark.svg");
    }

    &.spinner {
        right: calc(var(--spacing-s) - 3px);
        height: $spinner-size;
        width: $spinner-size;
        background-size: $spinner-size;
        background-image: url("../../images/userfrontend/spinner_dark.svg");
    }
}
