.tab-box {
    border: $border;
    background-color: #fff;

    .tabs {
        background-color: $gradient-grey-light-light;

        ul {
            @include list-no-bullets();
            @include flex();

            flex-direction: row;
            flex-wrap: wrap;

            li {
                display: inline-block;
                flex: 1 auto;
                text-align: center;
                border-right: $border;
                border-bottom: $border;

                background-color: $gradient-grey-light-light;
                background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark);

                a {
                    color: $type-grey-light;
                    font-size: $font-size-t1;
                    text-decoration: none;
                    padding: 11px 10px 9px 9px;
                    display: block;
                    cursor: pointer;

                    // Bei invalidem Form im Tab
                    &.error {
                        box-shadow: inset 0 0 12px 1px $alert-color;
                    }
                }

                &:hover {
                    background-color: $gradient-grey-light-light;
                    background-image: linear-gradient(to bottom, $gradient-grey-light-light, $gradient-grey-light-dark 50%);
                    text-decoration: none;

                    a {
                        color: $type-grey-medium;
                        background-color: transparent;
                    }
                }

                &.active {
                    background: none #fff;
                    border-bottom: none;

                    &:hover {
                        background: none #fff;
                        border-bottom: none;
                    }

                    a {
                        color: $type-grey-medium;
                        background-color: transparent;

                        &.error {
                            box-shadow: inset 0 0 5px 0 $alert-color;
                        }
                    }
                }

                &:last-child {
                    border-right: none;
                }

                @media all and (max-width: $medium-breakpoint) {
                    width: 100%;
                    border-right: none;

                    &:not(:last-child).active {
                        border-bottom: $border;
                    }
                }
            }
        }

    }

    .content {
        background-color: #fff;
        color: $type-grey-medium;
        padding: var(--spacing) var(--spacing-xs) 0;
        font-size: $font-size-t1;
        width: 100%;
        display: none;
        float: left;

        &.active {
            float: none;
            display: block;
        }

        > *:last-child {
            margin-bottom: var(--spacing);
        }
    }

    .tabs-content {
        margin-bottom: 0;
    }
}

.tabs-content .alerts {
    margin: var(--spacing);
}
