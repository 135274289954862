.flex {
    @include flex();

    > *:not(:last-child) {
        margin: 0 var(--spacing-xxs) 0 0;
    }

    > * {
        flex: 0 1 auto;
    }

    .keep-size {
        flex-shrink: 0;
    }

    &.center-items {
        align-items: center;
    }
}
