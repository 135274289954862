.interaction-grid table tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

tr.row-ready-to-publish td {
    background-color: $action-blue-light-light;
}

tr.row-published-version td {
    background-color: $green-light;
}

span.grid_boolean_true.rollback {
    background-image: url("../../images/interactionauthor/confirm_rollback.svg");
    $size: 24px;
    background-size: $size;
    height: $size;
    width: $size;
}
