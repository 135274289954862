//.key-visual {
//    background: url("../../images/covid/key_visual.jpg") center center no-repeat;
//}
.covid-key-visual {
    background: url("../../images/covid/key_visual.jpg") center center no-repeat;
    width: 100%;
    height: 275px;
    overflow: hidden;
}

.covid-key-visual-text-outer {
    @keyframes slide-in-from-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    animation: slide-in-from-right 1.5s ease-in-out 0s 1 forwards;
    background-color: transparent;
    height: 100%;
    width: 420px;
    padding-right: var(--spacing);
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $small-breakpoint) {
        width: auto;
        padding: var(--spacing-s);
    }
}

h2.claim {
    margin: var(--spacing) 0 0 0;

    span {
        vertical-align: middle;
        text-align: center;
        color: #fff;
        display: block;
    }
}

@media only screen and (min-width: $small-breakpoint + 1) {
    .covid-key-visual-overlay:first-of-type {
        @keyframes skew {
            from {
                transform: skewX(-2deg);
            }
            to {
                transform: skewX(-10deg);
            }
        }
        animation: skew 1.5s ease-in-out 0s 1 forwards;

        background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency);

        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }

    .covid-key-visual-overlay:nth-of-type(2) {
        @keyframes skew2 {
            from {
                transform: skewX(-30deg);
            }
            to {
                transform: skewX(-15deg);
            }
        }
        animation: skew2 2s ease-in-out 0s 1 forwards;
        background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency-light);
        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }
}

@media only screen and (max-width: $small-breakpoint) {
    .covid-key-visual-overlay:first-of-type {
        background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency);
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .covid-key-visual-overlay:nth-of-type(2) {
        @keyframes skew2 {
            from {
                transform: skewX(-30deg);
            }
            to {
                transform: skewX(-15deg);
            }
        }
        animation: skew2 2s ease-in-out 0s 1 forwards;
        background-color: rgba($covid-key-visual-bg-color, $covid-key-visual-bg-transparency-light);
        height: 100%;
        width: 500px;
        right: -50px;
        position: absolute;
        transition: all .1s ease-in-out;
    }
}

