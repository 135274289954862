.small-action-button, .action-button, .expander-button {
    &.edit:before {
        background-image: url("../../images/userfrontend/pencil.svg");
    }

    &.edit-user:before {
        background-image: url("../../images/userfrontend/edit_user.svg");
    }

    &.copy:before {
        background-image: url("../../images/userfrontend/copy.svg");
    }

    &.share:before {
        background-image: url("../../images/userfrontend/share.svg");
    }

    &.rename:before {
        background-image: url("../../images/userfrontend/file-pencil.svg");
    }

    &.start-discussion:before {
        background-image: url("../../images/userfrontend/start-discussion.svg");
    }

    &.create:before {
        background-image: url("../../images/userfrontend/create.svg");
    }

    &.external-link:before {
        background-image: url("../../images/userfrontend/external_link.svg");
    }

    &.add:before {
        background-image: url("../../images/userfrontend/add.svg");
    }

    &.delete:before {
        background-image: url("../../images/userfrontend/cross.svg");
    }

    &.reset:before {
        background-image: url("../../images/userfrontend/ccw.svg");
    }

    &.filter:before {
        background-image: url("../../images/userfrontend/filter.svg");
    }

    &.statistics:before {
        background-image: url("../../images/userfrontend/chart_upward.svg");
    }

    &.export:before {
        background-image: url("../../images/userfrontend/drawer_download.svg");
    }

    &.merge:before {
        background-image: url("../../images/userfrontend/merge.svg");
    }

    &.confirmation-mail:before {
        background-image: url("../../images/userfrontend/confirmation_mail.svg");
    }

    &.add-metabolic-transformation:before {
        background-image: url("../../images/userfrontend/flask_add.svg");
    }

    &.print:before {
        background-image: url("../../images/userfrontend/printer.svg");
    }

    &.version-list:before {
        background-image: url("../../images/interactionauthor/file_list.svg");
    }

    &.show:before {
        background-image: url("../../images/interactionauthor/eye.svg");
    }

    &.version-show:before {
        background-image: url("../../images/interactionauthor/file_preview.svg");
    }

    &.version-compare:before {
        background-image: url("../../images/interactionauthor/files_compare.svg");
    }

    &.version-publish:before {
        background-image: url("../../images/interactionauthor/book.svg");
    }

    &.version-unpublish:before {
        background-image: url("../../images/interactionauthor/book_bookmarked.svg");
    }

    &.version-rollback:before {
        background-image: url("../../images/interactionauthor/rollback.svg");
    }

    &.version-show-newest:before {
        background-image: url("../../images/interactionauthor/file_newest.svg");
    }

    &.version-show-previous:before {
        background-image: url("../../images/interactionauthor/file_previous.svg");
    }

    &.version-show-next:before {
        background-image: url("../../images/interactionauthor/file_next.svg");
    }

    &.take-editorship:before {
        background-image: url("../../images/interactionauthor/flag3.svg");
    }

    &.favourite:before {
        background-image: url("../../images/userfrontend/heart-plus.svg");
    }

    &.preview:before {
        background-image: url("../../images/userfrontend/file-preview-black.svg");
    }

    &.pdf-export:before {
        background-image: url("../../images/userfrontend/pdf.svg");
    }

    &.user:before {
        background-image: url("../../images/userfrontend/user.svg");
    }

    &.logout:before {
        background-image: url("../../images/userfrontend/logout.svg");
    }
}
