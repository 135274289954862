.nested-ordered-list {
    ol {
        counter-reset: item
    }

    ol > li {
        display: block
    }

    ol > li:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
    }

    ol > li > ol > li {
        display: flex;
    }

    ol > li > ol > li:before {
        content: counters(item, ".") " ";
        counter-increment: item;
        margin-right: var(--spacing-s);
    }

    span.single-item {
        display: block;
        margin: 15px 0 15px 80px;
    }


}
