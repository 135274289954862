.authors-list {
    img {
        height: 100%;
        flex: 0 0 110px;
    }

    img + div {
        padding-left: var(--spacing);
    }

    > div {
        @include flex();
        margin-bottom: var(--spacing);
        border-bottom: $border;
        padding-bottom: var(--spacing-xs);
    }
}

.author-profession {
    font-style: italic;
    padding-bottom: var(--spacing-xs);
}
