#interaction-diff {
    white-space: pre-wrap;

    background: transparent;
    padding: 0;

    .hljs-comment, .hljs-meta {
        display: none;
    }
}

#interaction-version-compare-dropdown-outer {
    display: inline-flex;
    width: 100%;
    min-width: var(--interaction-version-compare-dropdown-outer-min-width);

    &:hover, &:focus, &:active {
        background-color: transparent;
        box-shadow: none;
        color: $type-grey-medium;
        outline: none;
    }

    > span {
        flex-shrink: 0;
        pointer-events: none;
    }

    > div {
        flex-grow: 1;

        #interaction-version-compare-dropdown {
            height: 100%;
            position: absolute;
            z-index: 10000;
            overflow: hidden;
            left: 2px;

            a, .placeholder {
                padding: 0 var(--spacing-xxs);
                line-height: calc(var(--spacing) - 1px);
                display: block;

                &:not(:first-child) {
                    padding-top: var(--spacing-xxs);
                    color: #fff;
                }

                &:first-child {
                    text-decoration: underline dashed $action-blue;
                    color: $action-blue;
                }

                @media screen and (min-width: $medium-breakpoint + 1) {
                    &:nth-child(2) {
                        text-decoration: underline dashed $action-blue;
                        color: $action-blue;
                        padding-top: 0;
                    }
                }

                &:hover {
                    background-color: #fff;
                    color: $action-blue;
                    text-decoration: none;
                }
            }


            &:hover {
                overflow: visible;
                height: auto;
                background-color: $mobile-dark-background;
                outline: 1px solid $mobile-border-top;
                box-shadow: 9px 9px 8px 1px rgba(0, 0, 0, 0.5);

                a:first-of-type:not(:hover) {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .seperator {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-style: dashed;
                padding: var(--spacing-xs) 0 0 0;
                margin-bottom: calc(var(--spacing-xs) - var(--spacing-xxs));
            }
        }
    }
}
