.interaction-version-history-action-list-cell {
    width: 270px;
    vertical-align: top;

    .interaction-version-history-action-list {
        margin: 0;
        padding: 0 0 0 var(--spacing);
        list-style: decimal;
        text-align: left;

        li {
            font-family: $font-family-monospace;
            font-size: $font-size-small;
        }
    }
}

.horizontal-dots-height {
    height: var(--spacing);
}

.horizontal-dots-content {
    width: 17px;
    height: 17px;
    position:absolute;
    left:0; right:0;
    top:0; bottom:0;
    margin:auto;
    max-width:100%;
    max-height:100%;
    overflow:auto;
}

.horizontal-dots-circle {
    position: relative;
    background: $grey;
    width: 17px;
    height: 17px;
    color: $horizontal-dots-black-light;
    border-radius: 50%;
    border:1px solid $grey;
}

.horizontal-dots-circle:after {
    cursor: pointer;
    content: '...';
    position: absolute;
    top: 25%;
    left: 52%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    letter-spacing: 1px;
}

.horizontal-dots-circle:hover {
    cursor: pointer;
    background: $horizontal-dots-background-hover;
    color: $horizontal-dots-black-light;
}

.horizontal-dots-circle-toggle {
    background: $accent-orange;
    color: $divider-white;
}
