.top-action-button {
    vertical-align: top;
    overflow: visible;
    display: block;
    border: none;
    text-align: center;
    padding: var(--spacing-xxs) var(--spacing-l);

    &::before {
        content: "";
        display: inline-block;
        height: var(--action-button-icon-size);
        vertical-align: middle;
    }
}
.top-action-button-container {
    .top-action-button {
        display: inline-block;

        &:hover {
            text-decoration: none;
        }
    }
}

.top-action-button-container-outer, *:not(.top-action-button-container-outer) > .top-action-button-container:not(td) {
    @media only screen and (min-width: $small-breakpoint + 1) {
        z-index: 1000;
        &:not(.float) {
            position: absolute;
            display: inline-block;
            top: 0;
            right: calc(var(--side-space) + 10px);
        }

        &.float {
            float: right;
            margin-left: var(--spacing);
            margin-top: -$font-size-h1 / 2;
            margin-bottom: 0;
        }
    }
}

.top-action-button-container:not(td) {
    margin-bottom: var(--spacing);

    .top-action-button {
        justify-content: flex-start;
        line-height: calc(var(--action-button-icon-size) - 1px);
        text-decoration: none;
    }

    .top-action-button:not(:last-child) {
        margin-bottom: var(--spacing-xs);
    }

    .metadata {
        width: 100%;
    }
}
