header {
    position: relative;
    background-image: $header-footer-bg-image;
    min-height: 75px;

    .title {
        font-family: $font-family-headline;
        font-size: $header-title-fontsize;
        color: $springer-logo-blue;
        letter-spacing: 1px;
        padding: 34px 0 42px calc(var(--side-space) - 1px);
        display: inline-block;

        &:hover, &:focus, &:active {
            color: $springer-logo-blue;
            text-decoration: none;
        }

        @media only screen and (max-width: $small-breakpoint) {
            padding: var(--spacing) 0 var(--spacing) calc(var(--side-space) - 1px);
            font-size: $header-title-fontsize-small;
        }
    }

    .logo-container {
        position: absolute;
        top: 0;

        > * {
            display: inline-block;
        }

        .logo {
            padding-top: var(--spacing-s);

            @media only screen and (min-width: $small-breakpoint + 1) {
                border-left: $logo-border;
                -o-box-shadow: inset 1px 0 0 0 $logo-box-shadow;
                box-shadow: inset 1px 0 0 0 $logo-box-shadow;
                padding-left: var(--spacing);
            }
        }

        .current-user-info {
            padding-right: var(--spacing);
            bottom: var(--spacing-xs);
            color: $menu-font-color;

            a {
                color: $sign-out-link-color;
            }
        }

        right: var(--side-space);

        @media only screen and (max-width: $small-breakpoint) {
            .logo {
                padding-top: var(--spacing-s);
            }

            .logo img {
                width: 65px;
            }
        }
    }

    .menu-container ~ .logo-container {
        @media only screen and (max-width: $small-breakpoint) {
            right: calc(var(--side-space) + #{$burger-button-width} + var(--spacing));
        }
    }

}

div:not(.startpage) header {
    margin-bottom: $margin-top-to-center;
}

