@for $i from 2 through 6 {
    .small-block-grid-#{$i} {
        @include column-count($i);
    }

    @media only screen and (min-width: $medium-breakpoint + 1) {
        .medium-block-grid-#{$i} {
            @include column-count($i);
        }
    }

    @media only screen and (min-width: $medium-breakpoint + 1) {
        .large-block-grid-#{$i} {
            @include column-count($i);
        }
    }
}
